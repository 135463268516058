import React from "react";
import { Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
/**
 *
 * @param {*} param0
 * @returns
 */
export function Result({ claimType, labelAndValues, index, onRemove }) {
  const renderResultText = (lav) => {
    return lav.map((l) => {
      return (
        <ResultText
          key={l.label}
          label={`${l.label}: `}
          text={l.value}
          type={l.type}
          size={l.size}
        />
      );
    });
  };
  return (
    <Transition
      appear={true}
      show={true}
      enter="transition-opacity duration-500"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-transform duration-1000"
      leaveTo="scale-0"
    >
      <div className="bg-gray-100 container mx-auto grid gap-x-8 gap-y-4 grid-cols-4 justify-center border-gray-200 border-solid border-2 p-4 rounded-lg m-2">
        <div className="col-span-3">
          <HeaderTwo>{claimType}</HeaderTwo>
        </div>
        <div className="col-span-1 justify-self-end">
          <button onClick={() => onRemove(index)}>
            <XIcon className="stroke-current text-red h-6 w-6" />
          </button>
        </div>
        {renderResultText(labelAndValues)}
      </div>
    </Transition>
  );
}

function HeaderTwo(props) {
  return (
    <div className="text-xl font-bold text-gray-900 flex items-center">
      {props.children}
    </div>
  );
}

function ResultText({ label, text, size, type }) {
  function renderLabel(l) {
    return <div className="text-base font-semibold text-gray-900">{l}</div>;
  }
  function renderText(t) {
    if (type === "currency") {
      return (
        <div className="text-base font-normal flex items-center">
          {t.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
          })}
        </div>
      );
    }
    return <div className="text-base font-normal flex items-center">{t}</div>;
  }
  if (size === "lg") {
    return (
      <div className={`col-span-4 inline-flex`}>
        {renderLabel(label)}
        {renderText(text)}
      </div>
    );
  }
  return (
    <div className={`inline-flex`}>
      {renderLabel(label)}
      {renderText(text)}
    </div>
  );
}

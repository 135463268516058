import { createContext, useContext, useEffect, useState } from "react";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const config = {
  apiKey: "AIzaSyCWzF_gC5zbhTaGHz9AXU9LiK3mNaKkk20",
  authDomain: "consolidated-billing-assistant.firebaseapp.com",
  databaseURL: "https://consolidated-billing-assistant.firebaseio.com",
  projectId: "consolidated-billing-assistant",
  storageBucket: "consolidated-billing-assistant.appspot.com",
  messagingSenderId: "313576376388",
  appId: "1:313576376388:web:4cbc9a6115bfdf6da5d411",
  measurementId: "G-VW1Q63191M",
};
firebase.initializeApp(config);
const FirebaseContext = createContext();
/**
 * hook to get firebase instance
 * @returns
 */
export function useFirebase() {
  const firebaseContext = useContext(FirebaseContext);
  return firebaseContext;
}
// Configure FirebaseUI.
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: "popup",
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
  ],
  callbacks: {
    // Avoid redirects after sign-in.
    signInSuccessWithAuthResult: () => false,
  },
};
/**
 *
 * @param {*} props
 * @returns
 */
export function FirebaseProvider(props) {
  const [isSignedIn, setIsSignedIn] = useState(false); // Local signed-in state.

  // Listen to the Firebase Auth state and set the local state.
  useEffect(() => {
    const unregisterAuthObserver = firebase
      .auth()
      .onAuthStateChanged((user) => {
        setIsSignedIn(!!user);
      });
    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  }, []);
  if (!isSignedIn) {
    return (
      <div>
        <p>Please sign-in:</p>
        <StyledFirebaseAuth
          uiConfig={uiConfig}
          firebaseAuth={firebase.auth()}
        />
      </div>
    );
  }
  return (
    <FirebaseContext.Provider value={firebase}>
      {props.children}
    </FirebaseContext.Provider>
  );
}

import {v4} from 'uuid';

export function getLab(code, claimDate, claimAmount, attributes) {
  const labResponse = lookupArray.filter(l => l.code.toString().toUpperCase() === code.toString().toUpperCase())[0];
  if(!labResponse){
    throw new Error('Not Found');
  }
  labResponse.claimAmount = claimAmount;
  labResponse.claimDate = claimDate;
  labResponse.cost = parseFloat(labResponse.cost.toString().replace(/,/g, ''));
  const labelAndValues = attributes.map(attribute => {
    return {
      id: attribute.id,
      label: attribute.label,
      value: labResponse[attribute.id],
      ...attribute
    }
  })
  const result = {
    key: v4(),
    claimType: 'labs',
    labelAndValues: labelAndValues
  }
    return Promise.resolve(result);
      
}
export function doesLabExist(claimCode){
  return lookupArray.findIndex(l => l.code === claimCode) !== -1;
}
export const lookupArray = [
    {
      code: "0001U",
      cost: 720,
      description: "Rbc dna hea 35 ag 11 bld grp",
    },
    {
      code: "0002M",
      cost: 503.4,
      description: "Liver dis 10 assays w/ash",
    },
    {
      code: "0002U",
      cost: 25,
      description: "Onc clrct 3 ur metab alg plp",
    },
    {
      code: "0003M",
      cost: 503.4,
      description: "Liver dis 10 assays w/nash",
    },
    {
      code: "0003U",
      cost: 950,
      description: "Onc ovar 5 prtn ser alg scor",
    },
    {
      code: "0004M",
      cost: 79,
      description: "Scoliosis dna alys",
    },
    {
      code: "0005U",
      cost: 760,
      description: "Onco prst8 3 gene ur alg",
    },
    {
      code: "0006M",
      cost: 150,
      description: "Onc hep gene risk classifier",
    },
    {
      code: "0006U",
      cost: 246.92,
      description: "Detc ia meds 120+ analytes",
    },
    {
      code: "0007M",
      cost: 375,
      description: "Onc gastro 51 gene nomogram",
    },
    {
      code: "0007U",
      cost: 114.43,
      description: "Rx test prsmv ur w/def conf",
    },
    {
      code: "0008U",
      cost: 597.91,
      description: "Hpylori detcj abx rstnc dna",
    },
    {
      code: "0009U",
      cost: 107,
      description: "Onc brst ca erbb2 amp/nonamp",
    },
    {
      code: "0010U",
      cost: 427.26,
      description: "Nfct ds strn typ whl gen seq",
    },
    {
      code: "0011M",
      cost: 760,
      description: "Onc prst8 ca mrna 12 gen alg",
    },
    {
      code: "0011U",
      cost: 114.43,
      description: "Rx mntr lc-ms/ms oral fluid",
    },
    {
      code: "0012M",
      cost: 760,
      description: "Onc mrna 5 gen rsk urthl ca",
    },
    {
      code: "0012U",
      cost: "2,515.60",
      description: "Germln do gene reargmt detcj",
    },
    {
      code: "0013M",
      cost: 760,
      description: "Onc mrna 5 gen recr urthl ca",
    },
    {
      code: "0013U",
      cost: "2,515.60",
      description: "Onc sld org neo gene reargmt",
    },
    {
      code: "0014U",
      cost: "2,515.60",
      description: "Hem hmtlmf neo gene reargmt",
    },
    {
      code: "0016U",
      cost: 163.96,
      description: "Onc hmtlmf neo rna bcr/abl1",
    },
    {
      code: "0017U",
      cost: 91.66,
      description: "Onc hmtlmf neo jak2 mut dna",
    },
    {
      code: "0018U",
      cost: "3,002.09",
      description: "Onc thyr 10 microrna seq alg",
    },
    {
      code: "0019U",
      cost: "3,675.00",
      description: "Onc rna tiss predict alg",
    },
    {
      code: "0021U",
      cost: 760,
      description: "Onc prst8 detcj 8 autoantb",
    },
    {
      code: "0022U",
      cost: "1,950.00",
      description: "Trgt gen seq dna&rna 23 gene",
    },
    {
      code: "0023U",
      cost: 248.51,
      description: "Onc aml dna detcj/nondetcj",
    },
    {
      code: "0024U",
      cost: 34.19,
      description: "Glyca nuc mr spectrsc quan",
    },
    {
      code: "0025U",
      cost: 85.77,
      description: "Tenofovir liq chrom ur quan",
    },
    {
      code: "0026U",
      cost: "3,600.00",
      description: "Onc thyr dna&mrna 112 genes",
    },
    {
      code: "0027U",
      cost: 121.91,
      description: "Jak2 gene trgt seq alys",
    },
    {
      code: "0029U",
      cost: 742.27,
      description: "Rx metab advrs trgt seq alys",
    },
    {
      code: "0030U",
      cost: 134.13,
      description: "Rx metab warf trgt seq alys",
    },
    {
      code: "0031U",
      cost: 174.81,
      description: "Cyp1a2 gene",
    },
    {
      code: "0032U",
      cost: 174.81,
      description: "Comt gene",
    },
    {
      code: "0033U",
      cost: 349.62,
      description: "Htr2a htr2c genes",
    },
    {
      code: "0034U",
      cost: 466.17,
      description: "Tpmt nudt15 genes",
    },
    {
      code: "0035U",
      cost: 540.99,
      description: "Neuro csf prion prtn qual",
    },
    {
      code: "0036U",
      cost: "4,780.00",
      description: "Xome tum & nml spec seq alys",
    },
    {
      code: "0037U",
      cost: "3,500.00",
      description: "Trgt gen seq dna 324 genes",
    },
    {
      code: "0038U",
      cost: 29.6,
      description: "Vitamin d srm microsamp quan",
    },
    {
      code: "0039U",
      cost: 13.74,
      description: "Dna antb 2strand hi avidity",
    },
    {
      code: "0040U",
      cost: 409.9,
      description: "Bcr/abl1 gene major bp quan",
    },
    {
      code: "0041U",
      cost: 17.21,
      description: "B brgdrferi antb 5 prtn igm",
    },
    {
      code: "0042U",
      cost: 17.21,
      description: "B brgdrferi antb 12 prtn igg",
    },
    {
      code: "0043U",
      cost: 14.86,
      description: "Tbrf b grp antb 4 prtn igm",
    },
    {
      code: "0044U",
      cost: 14.86,
      description: "Tbrf b grp antb 4 prtn igg",
    },
    {
      code: "0045U",
      cost: "3,873.00",
      description: "Onc brst dux carc is 12 gene",
    },
    {
      code: "0046U",
      cost: 0,
      description: "Flt3 gene itd variants quan",
    },
    {
      code: "0047U",
      cost: "3,873.00",
      description: "Onc prst8 mrna 17 gene alg",
    },
    {
      code: "0048U",
      cost: "2,919.60",
      description: "Onc sld org neo dna 468 gene",
    },
    {
      code: "0049U",
      cost: 0,
      description: "Npm1 gene analysis quan",
    },
    {
      code: "0050U",
      cost: "2,916.60",
      description: "Trgt gen seq dna 194 genes",
    },
    {
      code: "0051U",
      cost: 193.71,
      description: "Rx mntr lc-ms/ms ur 31 pnl",
    },
    {
      code: "0052U",
      cost: 33.86,
      description: "Lpoprtn bld w/5 maj classes",
    },
    {
      code: "0053U",
      cost: "2,030.00",
      description: "Onc prst8 ca fish alys 4 gen",
    },
    {
      code: "0054U",
      cost: 148.96,
      description: "Rx mntr 14+ drugs & sbsts",
    },
    {
      code: "0055U",
      cost: "3,240.00",
      description: "Card hrt trnspl 96 dna seq",
    },
    {
      code: "0056U",
      cost: "2,515.60",
      description: "Hem aml dna gene reargmt",
    },
    {
      code: "0058U",
      cost: 322.96,
      description: "Onc merkel cll carc srm quan",
    },
    {
      code: "0059U",
      cost: 322.96,
      description: "Onc merkel cll carc srm +/-",
    },
    {
      code: "0060U",
      cost: 759.05,
      description: "Twn zyg gen seq alys chrms2",
    },
    {
      code: "0061U",
      cost: 25.1,
      description: "Tc meas 5 bmrk sfdi m-s alys",
    },
    {
      code: "0062U",
      cost: 0,
      description: "Ai sle igg&igm alys 80 bmrk",
    },
    {
      code: "0063U",
      cost: 0,
      description: "Neuro autism 32 amines alg",
    },
    {
      code: "0064U",
      cost: 31.33,
      description: "Antb tp total&rpr ia qual",
    },
    {
      code: "0065U",
      cost: 18.09,
      description: "Syfls tst nontreponemal antb",
    },
    {
      code: "0066U",
      cost: 15.29,
      description: "Pamg-1 ia cervico-vag fluid",
    },
    {
      code: "0067U",
      cost: 0,
      description: "Onc brst imhchem prfl 4 bmrk",
    },
    {
      code: "0068U",
      cost: 142.63,
      description: "Candida species pnl amp prb",
    },
    {
      code: "0069U",
      cost: 380,
      description: "Onc clrct microrna mir-31-3p",
    },
    {
      code: "0070U",
      cost: 676.37,
      description: "Cyp2d6 gen com&slct rar vrnt",
    },
    {
      code: "0071U",
      cost: 301.35,
      description: "Cyp2d6 full gene sequence",
    },
    {
      code: "0072U",
      cost: 450.91,
      description: "Cyp2d6 gen cyp2d6-2d7 hybrid",
    },
    {
      code: "0073U",
      cost: 450.91,
      description: "Cyp2d6 gen cyp2d7-2d6 hybrid",
    },
    {
      code: "0074U",
      cost: 450.91,
      description: "Cyp2d6 nonduplicated gene",
    },
    {
      code: "0075U",
      cost: 450.91,
      description: "Cyp2d6 5' gene dup/mlt",
    },
    {
      code: "0076U",
      cost: 450.91,
      description: "Cyp2d6 3' gene dup/mlt",
    },
    {
      code: "0077U",
      cost: 0,
      description: "Ig paraprotein qual bld/ur",
    },
    {
      code: "0078U",
      cost: 450.91,
      description: "Pain mgt opi use gnotyp pnl",
    },
    {
      code: "0080U",
      cost: "3,520.00",
      description: "Onc lng 5 clin rsk factr alg",
    },
    {
      code: "0082U",
      cost: 246.92,
      description: "Rx test def 90+ rx/sbsts ur",
    },
    {
      code: "0083U",
      cost: 0,
      description: "Onc rspse chemo cntrst tomog",
    },
    {
      code: "0084U",
      cost: 720,
      description: "Rbc dna gnotyp 10 bld groups",
    },
    {
      code: "0086U",
      cost: 0,
      description: "Nfct ds bact&fng org id 6+",
    },
    {
      code: "0087U",
      cost: 0,
      description: "Crd hrt trnspl mrna 1283 gen",
    },
    {
      code: "0088U",
      cost: 0,
      description: "Trnsplj kdn algrft rej 1494",
    },
    {
      code: "0089U",
      cost: 760,
      description: "Onc mlnma prame & linc00518",
    },
    {
      code: "0090U",
      cost: "1,950.00",
      description: "Onc cutan mlnma mrna 23 gene",
    },
    {
      code: "0092U",
      cost: 0,
      description: "Onc lng 3 prtn bmrk plsm alg",
    },
    {
      code: "0093U",
      cost: 62.14,
      description: "Rx mntr 65 com drugs urine",
    },
    {
      code: "0094U",
      cost: 0,
      description: "Genome rapid sequence alys",
    },
    {
      code: "0095U",
      cost: 0,
      description: "Inflm ee elisa alys alg",
    },
    {
      code: "0096U",
      cost: 35.09,
      description: "Hpv hi risk types male urine",
    },
    {
      code: "0097U",
      cost: 0,
      description: "Gi pathogen 22 targets",
    },
    {
      code: "0098U",
      cost: 0,
      description: "Respir pathogen 14 targets",
    },
    {
      code: "0099U",
      cost: 0,
      description: "Respir pathogen 20 targets",
    },
    {
      code: "0100U",
      cost: 0,
      description: "Respir pathogen 21 targets",
    },
    {
      code: "0101U",
      cost: "1,169.80",
      description: "Hered colon ca do 15 genes",
    },
    {
      code: "0102U",
      cost: "1,117.98",
      description: "Hered brst ca rltd do 17 gen",
    },
    {
      code: "0103U",
      cost: "1,117.98",
      description: "Hered ova ca pnl 24 genes",
    },
    {
      code: "0105U",
      cost: 950,
      description: "Neph ckd mult eclia tum nec",
    },
    {
      code: "0106U",
      cost: 0,
      description: "Gstr emptg 7 timed brth spec",
    },
    {
      code: "0107U",
      cost: 16,
      description: "C diff tox ag detcj ia stool",
    },
    {
      code: "0108U",
      cost: 0,
      description: "Gi barrett esoph 9 prtn bmrk",
    },
    {
      code: "0109U",
      cost: 142.63,
      description: "Id aspergillus dna 4 species",
    },
    {
      code: "0110U",
      cost: 27.11,
      description: "Rx mntr 1+oral onc rx&sbsts",
    },
    {
      code: "0111U",
      cost: 682.29,
      description: "Onc colon ca kras&nras alys",
    },
    {
      code: "0112U",
      cost: 0,
      description: "Iadi 16s&18s rrna genes",
    },
    {
      code: "0113U",
      cost: 760,
      description: "Onc prst8 pca3&tmprss2-erg",
    },
    {
      code: "0114U",
      cost: 0,
      description: "Gi barretts esoph vim&ccna1",
    },
    {
      code: "0115U",
      cost: 0,
      description: "Respir iadna 18 viral&2 bact",
    },
    {
      code: "0116U",
      cost: 246.92,
      description: "Rx mntr nzm ia 35+oral flu",
    },
    {
      code: "0117U",
      cost: 0,
      description: "Pain mgmt 11 endogenous anal",
    },
    {
      code: "0118U",
      cost: 0,
      description: "Trnsplj don-drv cll-fr dna",
    },
    {
      code: "0119U",
      cost: 0,
      description: "Crd ceramides liq chrom plsm",
    },
    {
      code: "0120U",
      cost: "2,510.21",
      description: "Onc b cll lymphm mrna 58 gen",
    },
    {
      code: "0121U",
      cost: 0,
      description: "Sc dis vcam-1 whole blood",
    },
    {
      code: "0122U",
      cost: 0,
      description: "Sc dis p-selectin whl blood",
    },
    {
      code: "0123U",
      cost: 0,
      description: "Mchnl fragility rbc prflg",
    },
    {
      code: "0124U",
      cost: 55.54,
      description: "Ftl cgen abnor 3 analytes",
    },
    {
      code: "0125U",
      cost: 69.52,
      description: "Ftl cgen abnor prnt comp 5",
    },
    {
      code: "0126U",
      cost: 69.52,
      description: "Ftl cgen abnor prnt comp 5 y",
    },
    {
      code: "0127U",
      cost: 55.54,
      description: "Ob pe 3 analytes",
    },
    {
      code: "0128U",
      cost: 55.54,
      description: "Ob pe 3 analytes y chrmsm",
    },
    {
      code: "0129U",
      cost: "1,117.98",
      description: "Hered brst ca rltd do panel",
    },
    {
      code: "0130U",
      cost: 584.9,
      description: "Hered colon ca do mrna pnl",
    },
    {
      code: "0131U",
      cost: 0,
      description: "Hered brst ca rltd do pnl 13",
    },
    {
      code: "0132U",
      cost: 0,
      description: "Hered ova ca rltd do pnl 17",
    },
    {
      code: "0133U",
      cost: 0,
      description: "Hered prst8 ca rltd do 11",
    },
    {
      code: "0134U",
      cost: 0,
      description: "Hered pan ca mrna pnl 18 gen",
    },
    {
      code: "0135U",
      cost: 0,
      description: "Hered gyn ca mrna pnl 12 gen",
    },
    {
      code: "0136U",
      cost: 0,
      description: "Atm mrna seq alys",
    },
    {
      code: "0137U",
      cost: 0,
      description: "Palb2 mrna seq alys",
    },
    {
      code: "0138U",
      cost: 0,
      description: "Brca1 brca2 mrna seq alys",
    },
    {
      code: "0139U",
      cost: 0,
      description: "Neuro austm meas 6 c metablt",
    },
    {
      code: "0140U",
      cost: 0,
      description: "Nfct ds fungi dna 15 trgt",
    },
    {
      code: "0141U",
      cost: 0,
      description: "Nfct ds bact&fng gram pos",
    },
    {
      code: "0142U",
      cost: 0,
      description: "Nfct ds bact&fng gram neg",
    },
    {
      code: "0143U",
      cost: 0,
      description: "Drug assay 120+ rx/metablt",
    },
    {
      code: "0144U",
      cost: 0,
      description: "Drug assay 160+ rx/metablt",
    },
    {
      code: "0145U",
      cost: 0,
      description: "Drug assay 65+ rx/metablt",
    },
    {
      code: "0146U",
      cost: 0,
      description: "Drug assay 80+ rx/metablt",
    },
    {
      code: "0147U",
      cost: 0,
      description: "Drug assay 85+ rx/metablt",
    },
    {
      code: "0148U",
      cost: 0,
      description: "Drug assay 100+ rx/metablt",
    },
    {
      code: "0149U",
      cost: 0,
      description: "Drug assay 60+ rx/metablt",
    },
    {
      code: "0150U",
      cost: 0,
      description: "Drug assay 120+ rx/metablt",
    },
    {
      code: "0151U",
      cost: 0,
      description: "Nfct bct/vir resp nfctj 33",
    },
    {
      code: "0152U",
      cost: 0,
      description: "Nfct bct fng prst dna >1000",
    },
    {
      code: "0153U",
      cost: 0,
      description: "Onc breast mrna 101 genes",
    },
    {
      code: "0154U",
      cost: 0,
      description: "Fgfr3 gene analysis",
    },
    {
      code: "0155U",
      cost: 0,
      description: "Pik3ca gene analysis",
    },
    {
      code: "0156U",
      cost: 0,
      description: "Copy number sequence alys",
    },
    {
      code: "0157U",
      cost: 0,
      description: "Apc mrna seq alys",
    },
    {
      code: "0158U",
      cost: 0,
      description: "Mlh1 mrna seq alys",
    },
    {
      code: "0159U",
      cost: 0,
      description: "Msh2 mrna seq alys",
    },
    {
      code: "0160U",
      cost: 0,
      description: "Msh6 mrna seq alys",
    },
    {
      code: "0161U",
      cost: 0,
      description: "Pms2 mrna seq alys",
    },
    {
      code: "0162U",
      cost: 0,
      description: "Hered colon ca trgt mrna pnl",
    },
    {
      code: 36415,
      cost: 3,
      description: "Routine venipuncture",
    },
    {
      code: 78267,
      cost: 11.06,
      description: "Breath tst attain/anal c-14",
    },
    {
      code: 78268,
      cost: 94.41,
      description: "Breath test analysis c-14",
    },
    {
      code: 80047,
      cost: 13.73,
      description: "Metabolic panel ionized ca",
    },
   
    {
      code: 80048,
      cost: 8.46,
      description: "Metabolic panel total ca",
    },
   
    {
      code: 80051,
      cost: 7.01,
      description: "Electrolyte panel",
    },
    {
      code: 80053,
      cost: 10.56,
      description: "Comprehen metabolic panel",
    },
    {
      code: 80055,
      cost: 47.81,
      description: "Obstetric panel",
    },
    {
      code: 80061,
      cost: 13.39,
      description: "Lipid panel",
    },
    {
      code: 80069,
      cost: 8.68,
      description: "Renal function panel",
    },
    {
      code: 80069,
      cost: 8.68,
      description: "Renal function panel",
    },
    {
      code: 80074,
      cost: 47.63,
      description: "Acute hepatitis panel",
    },
    {
      code: 80076,
      cost: 8.17,
      description: "Hepatic function panel",
    },
    {
      code: 80081,
      cost: 74.86,
      description: "Obstetric panel",
    },
    {
      code: 80145,
      cost: 38.57,
      description: "Drug assay adalimumab",
    },
    {
      code: 80150,
      cost: 15.08,
      description: "Assay of amikacin",
    },
    {
      code: 80155,
      cost: 38.57,
      description: "Drug assay caffeine",
    },
    {
      code: 80156,
      cost: 14.57,
      description: "Assay carbamazepine total",
    },
    {
      code: 80157,
      cost: 13.25,
      description: "Assay carbamazepine free",
    },
    {
      code: 80158,
      cost: 18.05,
      description: "Drug assay cyclosporine",
    },
    {
      code: 80159,
      cost: 20.15,
      description: "Drug assay clozapine",
    },
    {
      code: 80162,
      cost: 13.28,
      description: "Assay of digoxin total",
    },
    {
      code: 80163,
      cost: 13.28,
      description: "Assay of digoxin free",
    },
    {
      code: 80164,
      cost: 13.54,
      description: "Assay dipropylacetic acd tot",
    },
    {
      code: 80165,
      cost: 13.54,
      description: "Dipropylacetic acid free",
    },
    {
      code: 80168,
      cost: 16.34,
      description: "Assay of ethosuximide",
    },
    {
      code: 80169,
      cost: 13.73,
      description: "Drug assay everolimus",
    },
    {
      code: 80170,
      cost: 16.38,
      description: "Assay of gentamicin",
    },
    {
      code: 80171,
      cost: 21.67,
      description: "Drug screen quant gabapentin",
    },
    {
      code: 80173,
      cost: 15.78,
      description: "Assay of haloperidol",
    },
    {
      code: 80175,
      cost: 13.25,
      description: "Drug screen quan lamotrigine",
    },
    {
      code: 80176,
      cost: 14.69,
      description: "Assay of lidocaine",
    },
    {
      code: 80177,
      cost: 13.25,
      description: "Drug scrn quan levetiracetam",
    },
    {
      code: 80178,
      cost: 6.61,
      description: "Assay of lithium",
    },
    {
      code: 80178,
      cost: 6.61,
      description: "Assay of lithium",
    },
    {
      code: 80180,
      cost: 18.05,
      description: "Drug scrn quan mycophenolate",
    },
    {
      code: 80183,
      cost: 13.25,
      description: "Drug scrn quant oxcarbazepin",
    },
    {
      code: 80184,
      cost: 15.3,
      description: "Assay of phenobarbital",
    },
    {
      code: 80185,
      cost: 13.25,
      description: "Assay of phenytoin total",
    },
    {
      code: 80186,
      cost: 13.76,
      description: "Assay of phenytoin free",
    },
    {
      code: 80187,
      cost: 27.11,
      description: "Drug assay posaconazole",
    },
    {
      code: 80188,
      cost: 16.59,
      description: "Assay of primidone",
    },
    {
      code: 80190,
      cost: 60,
      description: "Assay of procainamide",
    },
    {
      code: 80192,
      cost: 16.75,
      description: "Assay of procainamide",
    },
    {
      code: 80194,
      cost: 14.6,
      description: "Assay of quinidine",
    },
    {
      code: 80195,
      cost: 13.73,
      description: "Assay of sirolimus",
    },
    {
      code: 80197,
      cost: 13.73,
      description: "Assay of tacrolimus",
    },
    {
      code: 80198,
      cost: 14.14,
      description: "Assay of theophylline",
    },
    {
      code: 80199,
      cost: 27.11,
      description: "Drug screen quant tiagabine",
    },
    {
      code: 80200,
      cost: 16.13,
      description: "Assay of tobramycin",
    },
    {
      code: 80201,
      cost: 11.92,
      description: "Assay of topiramate",
    },
    {
      code: 80202,
      cost: 13.54,
      description: "Assay of vancomycin",
    },
    {
      code: 80203,
      cost: 13.25,
      description: "Drug screen quant zonisamide",
    },
    {
      code: 80230,
      cost: 38.57,
      description: "Drug assay infliximab",
    },
    {
      code: 80235,
      cost: 27.11,
      description: "Drug assay lacosamide",
    },
    {
      code: 80280,
      cost: 38.57,
      description: "Drug assay vedolizumab",
    },
    {
      code: 80285,
      cost: 27.11,
      description: "Drug assay voriconazole",
    },
    {
      code: 80299,
      cost: 18.64,
      description: "Quantitative assay drug",
    },
    {
      code: 80305,
      cost: 12.6,
      description: "Drug test prsmv dir opt obs",
    },
    {
      code: 80305,
      cost: 12.6,
      description: "Drug test prsmv dir opt obs",
    },
    {
      code: 80306,
      cost: 17.14,
      description: "Drug test prsmv instrmnt",
    },
    {
      code: 80307,
      cost: 62.14,
      description: "Drug test prsmv chem anlyzr",
    },
    {
      code: 80400,
      cost: 32.62,
      description: "Acth stimulation panel",
    },
    {
      code: 80402,
      cost: 86.96,
      description: "Acth stimulation panel",
    },
    {
      code: 80406,
      cost: 78.26,
      description: "Acth stimulation panel",
    },
    {
      code: 80408,
      cost: 125.5,
      description: "Aldosterone suppression eval",
    },
    {
      code: 80410,
      cost: 80.37,
      description: "Calcitonin stimul panel",
    },
    {
      code: 80412,
      cost: 801.62,
      description: "Crh stimulation panel",
    },
    {
      code: 80414,
      cost: 51.64,
      description: "Testosterone response",
    },
    {
      code: 80415,
      cost: 55.89,
      description: "Estradiol response panel",
    },
    {
      code: 80416,
      cost: 209.32,
      description: "Renin stimulation panel",
    },
    {
      code: 80417,
      cost: 43.99,
      description: "Renin stimulation panel",
    },
    {
      code: 80418,
      cost: 579.48,
      description: "Pituitary evaluation panel",
    },
    {
      code: 80420,
      cost: 161.88,
      description: "Dexamethasone panel",
    },
    {
      code: 80422,
      cost: 46.07,
      description: "Glucagon tolerance panel",
    },
    {
      code: 80424,
      cost: 50.5,
      description: "Glucagon tolerance panel",
    },
    {
      code: 80426,
      cost: 148.41,
      description: "Gonadotropin hormone panel",
    },
    {
      code: 80428,
      cost: 66.7,
      description: "Growth hormone panel",
    },
    {
      code: 80430,
      cost: 129.33,
      description: "Growth hormone panel",
    },
    {
      code: 80432,
      cost: 165.61,
      description: "Insulin suppression panel",
    },
    {
      code: 80434,
      cost: 285.03,
      description: "Insulin tolerance panel",
    },
    {
      code: 80435,
      cost: 103,
      description: "Insulin tolerance panel",
    },
    {
      code: 80436,
      cost: 91.16,
      description: "Metyrapone panel",
    },
    {
      code: 80438,
      cost: 50.41,
      description: "Trh stimulation panel",
    },
    {
      code: 80439,
      cost: 67.21,
      description: "Trh stimulation panel",
    },
    {
      code: 81000,
      cost: 4.02,
      description: "Urinalysis nonauto w/scope",
    },
    {
      code: 81001,
      cost: 3.17,
      description: "Urinalysis auto w/scope",
    },
    {
      code: 81002,
      cost: 3.48,
      description: "Urinalysis nonauto w/o scope",
    },
    {
      code: 81003,
      cost: 2.25,
      description: "Urinalysis auto w/o scope",
    },
    {
      code: 81003,
      cost: 2.25,
      description: "Urinalysis auto w/o scope",
    },
    {
      code: 81005,
      cost: 2.17,
      description: "Urinalysis",
    },
    {
      code: 81007,
      cost: 29.98,
      description: "Urine screen for bacteria",
    },
    {
      code: 81007,
      cost: 29.98,
      description: "Urine screen for bacteria",
    },
    {
      code: 81015,
      cost: 3.05,
      description: "Microscopic exam of urine",
    },
    {
      code: 81020,
      cost: 4.7,
      description: "Urinalysis glass test",
    },
    {
      code: 81025,
      cost: 8.61,
      description: "Urine pregnancy test",
    },
    {
      code: 81050,
      cost: 3.64,
      description: "Urinalysis volume measure",
    },
    {
      code: 81105,
      cost: 122.22,
      description: "Hpa-1 genotyping",
    },
    {
      code: 81106,
      cost: 122.22,
      description: "Hpa-2 genotyping",
    },
    {
      code: 81107,
      cost: 122.22,
      description: "Hpa-3 genotyping",
    },
    {
      code: 81108,
      cost: 122.22,
      description: "Hpa-4 genotyping",
    },
    {
      code: 81109,
      cost: 122.22,
      description: "Hpa-5 genotyping",
    },
    {
      code: 81110,
      cost: 122.22,
      description: "Hpa-6 genotyping",
    },
    {
      code: 81111,
      cost: 122.22,
      description: "Hpa-9 genotyping",
    },
    {
      code: 81112,
      cost: 122.22,
      description: "Hpa-15 genotyping",
    },
    {
      code: 81120,
      cost: 193.25,
      description: "Idh1 common variants",
    },
    {
      code: 81121,
      cost: 295.79,
      description: "Idh2 common variants",
    },
    {
      code: 81161,
      cost: 279,
      description: "Dmd dup/delet analysis",
    },
    {
      code: 81162,
      cost: "1,824.88",
      description: "Brca1&2 gen full seq dup/del",
    },
    {
      code: 81163,
      cost: 468,
      description: "Brca1&2 gene full seq alys",
    },
    {
      code: 81164,
      cost: 584.23,
      description: "Brca1&2 gen ful dup/del alys",
    },
    {
      code: 81165,
      cost: 282.88,
      description: "Brca1 gene full seq alys",
    },
    {
      code: 81166,
      cost: 301.35,
      description: "Brca1 gene full dup/del alys",
    },
    {
      code: 81167,
      cost: 282.88,
      description: "Brca2 gene full dup/del alys",
    },
    {
      code: 81170,
      cost: 300,
      description: "Abl1 gene",
    },
    {
      code: 81171,
      cost: 137,
      description: "Aff2 gene detc abnor alleles",
    },
    {
      code: 81172,
      cost: 274.83,
      description: "Aff2 gene charac alleles",
    },
    {
      code: 81173,
      cost: 301.35,
      description: "Ar gene full gene sequence",
    },
    {
      code: 81174,
      cost: 185.2,
      description: "Ar gene known famil variant",
    },
    {
      code: 81175,
      cost: 676.5,
      description: "Asxl1 full gene sequence",
    },
    {
      code: 81176,
      cost: 241.9,
      description: "Asxl1 gene target seq alys",
    },
    {
      code: 81177,
      cost: 137,
      description: "Atn1 gene detc abnor alleles",
    },
    {
      code: 81178,
      cost: 137,
      description: "Atxn1 gene detc abnor allele",
    },
    {
      code: 81179,
      cost: 137,
      description: "Atxn2 gene detc abnor allele",
    },
    {
      code: 81180,
      cost: 137,
      description: "Atxn3 gene detc abnor allele",
    },
    {
      code: 81181,
      cost: 137,
      description: "Atxn7 gene detc abnor allele",
    },
    {
      code: 81182,
      cost: 137,
      description: "Atxn8os gen detc abnor allel",
    },
    {
      code: 81183,
      cost: 137,
      description: "Atxn10 gene detc abnor allel",
    },
    {
      code: 81184,
      cost: 137,
      description: "Cacna1a gen detc abnor allel",
    },
    {
      code: 81185,
      cost: 846.27,
      description: "Cacna1a gene full gene seq",
    },
    {
      code: 81186,
      cost: 185.2,
      description: "Cacna1a gen known famil vrnt",
    },
    {
      code: 81187,
      cost: 137,
      description: "Cnbp gene detc abnor allele",
    },
    {
      code: 81188,
      cost: 137,
      description: "Cstb gene detc abnor allele",
    },
    {
      code: 81189,
      cost: 274.83,
      description: "Cstb gene full gene sequence",
    },
    {
      code: 81190,
      cost: 185.2,
      description: "Cstb gene known famil vrnt",
    },
    {
      code: 81200,
      cost: 47.25,
      description: "Aspa gene",
    },
    {
      code: 81201,
      cost: 780,
      description: "Apc gene full sequence",
    },
    {
      code: 81202,
      cost: 280,
      description: "Apc gene known fam variants",
    },
    {
      code: 81203,
      cost: 200,
      description: "Apc gene dup/delet variants",
    },
    {
      code: 81204,
      cost: 137,
      description: "Ar gene charac alleles",
    },
    {
      code: 81205,
      cost: 94.99,
      description: "Bckdhb gene",
    },
    {
      code: 81206,
      cost: 163.96,
      description: "Bcr/abl1 gene major bp",
    },
    {
      code: 81207,
      cost: 144.84,
      description: "Bcr/abl1 gene minor bp",
    },
    {
      code: 81208,
      cost: 214.62,
      description: "Bcr/abl1 gene other bp",
    },
    {
      code: 81209,
      cost: 39.31,
      description: "Blm gene",
    },
    {
      code: 81210,
      cost: 175.4,
      description: "Braf gene",
    },
    {
      code: 81212,
      cost: 440,
      description: "Brca1&2 185&5385&6174 vrnt",
    },
    {
      code: 81215,
      cost: 375.25,
      description: "Brca1 gene known famil vrnt",
    },
    {
      code: 81216,
      cost: 185.12,
      description: "Brca2 gene full seq alys",
    },
    {
      code: 81217,
      cost: 375.25,
      description: "Brca2 gene known famil vrnt",
    },
    {
      code: 81218,
      cost: 241.9,
      description: "Cebpa gene full sequence",
    },
    {
      code: 81219,
      cost: 121.63,
      description: "Calr gene com variants",
    },
    {
      code: 81220,
      cost: 556.6,
      description: "Cftr gene com variants",
    },
    {
      code: 81221,
      cost: 97.22,
      description: "Cftr gene known fam variants",
    },
    {
      code: 81222,
      cost: 435.07,
      description: "Cftr gene dup/delet variants",
    },
    {
      code: 81223,
      cost: 499,
      description: "Cftr gene full sequence",
    },
    {
      code: 81224,
      cost: 168.75,
      description: "Cftr gene intron poly t",
    },
    {
      code: 81225,
      cost: 291.36,
      description: "Cyp2c19 gene com variants",
    },
    {
      code: 81226,
      cost: 450.91,
      description: "Cyp2d6 gene com variants",
    },
    {
      code: 81227,
      cost: 174.81,
      description: "Cyp2c9 gene com variants",
    },
    {
      code: 81228,
      cost: 900,
      description: "Cytogen micrarray copy nmbr",
    },
    {
      code: 81229,
      cost: "1,160.00",
      description: "Cytogen m array copy no&snp",
    },
    {
      code: 81230,
      cost: 174.81,
      description: "Cyp3a4 gene common variants",
    },
    {
      code: 81231,
      cost: 174.81,
      description: "Cyp3a5 gene common variants",
    },
    {
      code: 81232,
      cost: 174.81,
      description: "Dpyd gene common variants",
    },
    {
      code: 81233,
      cost: 175.4,
      description: "Btk gene common variants",
    },
    {
      code: 81234,
      cost: 137,
      description: "Dmpk gene detc abnor allele",
    },
    {
      code: 81235,
      cost: 324.58,
      description: "Egfr gene com variants",
    },
    {
      code: 81236,
      cost: 282.88,
      description: "Ezh2 gene full gene sequence",
    },
    {
      code: 81237,
      cost: 175.4,
      description: "Ezh2 gene common variants",
    },
    {
      code: 81238,
      cost: 600,
      description: "F9 full gene sequence",
    },
    {
      code: 81239,
      cost: 274.83,
      description: "Dmpk gene charac alleles",
    },
    {
      code: 81240,
      cost: 65.69,
      description: "F2 gene",
    },
    {
      code: 81241,
      cost: 73.37,
      description: "F5 gene",
    },
    {
      code: 81242,
      cost: 36.62,
      description: "Fancc gene",
    },
    {
      code: 81243,
      cost: 57.04,
      description: "Fmr1 gene detection",
    },
    {
      code: 81244,
      cost: 44.89,
      description: "Fmr1 gene charac alleles",
    },
    {
      code: 81245,
      cost: 165.51,
      description: "Flt3 gene",
    },
    {
      code: 81246,
      cost: 83,
      description: "Flt3 gene analysis",
    },
    {
      code: 81247,
      cost: 174.81,
      description: "G6pd gene alys cmn variant",
    },
    {
      code: 81248,
      cost: 375.25,
      description: "G6pd known familial variant",
    },
    {
      code: 81249,
      cost: 600,
      description: "G6pd full gene sequence",
    },
    {
      code: 81250,
      cost: 58.49,
      description: "G6pc gene",
    },
    {
      code: 81251,
      cost: 47.25,
      description: "Gba gene",
    },
    {
      code: 81252,
      cost: 101.12,
      description: "Gjb2 gene full sequence",
    },
    {
      code: 81253,
      cost: 61.52,
      description: "Gjb2 gene known fam variants",
    },
    {
      code: 81254,
      cost: 35,
      description: "Gjb6 gene com variants",
    },
    {
      code: 81255,
      cost: 51.45,
      description: "Hexa gene",
    },
    {
      code: 81256,
      cost: 65.36,
      description: "Hfe gene",
    },
    {
      code: 81257,
      cost: 102.26,
      description: "Hba1/hba2 gene",
    },
    {
      code: 81258,
      cost: 375.25,
      description: "Hba1/hba2 gene fam vrnt",
    },
    {
      code: 81259,
      cost: 600,
      description: "Hba1/hba2 full gene sequence",
    },
    {
      code: 81260,
      cost: 39.31,
      description: "Ikbkap gene",
    },
    {
      code: 81261,
      cost: 197.99,
      description: "Igh gene rearrange amp meth",
    },
    {
      code: 81262,
      cost: 68.55,
      description: "Igh gene rearrang dir probe",
    },
    {
      code: 81263,
      cost: 294.52,
      description: "Igh vari regional mutation",
    },
    {
      code: 81264,
      cost: 172.73,
      description: "Igk rearrangeabn clonal pop",
    },
    {
      code: 81265,
      cost: 233.07,
      description: "Str markers specimen anal",
    },
    {
      code: 81266,
      cost: 304.81,
      description: "Str markers spec anal addl",
    },
    {
      code: 81267,
      cost: 207.46,
      description: "Chimerism anal no cell selec",
    },
    {
      code: 81268,
      cost: 260.79,
      description: "Chimerism anal w/cell select",
    },
    {
      code: 81269,
      cost: 202.4,
      description: "Hba1/hba2 gene dup/del vrnts",
    },
    {
      code: 81270,
      cost: 91.66,
      description: "Jak2 gene",
    },
    {
      code: 81271,
      cost: 137,
      description: "Htt gene detc abnor alleles",
    },
    {
      code: 81272,
      cost: 329.51,
      description: "Kit gene targeted seq analys",
    },
    {
      code: 81273,
      cost: 124.87,
      description: "Kit gene analys d816 variant",
    },
    {
      code: 81274,
      cost: 274.83,
      description: "Htt gene charac alleles",
    },
    {
      code: 81275,
      cost: 193.25,
      description: "Kras gene variants exon 2",
    },
    {
      code: 81276,
      cost: 193.25,
      description: "Kras gene addl variants",
    },
    {
      code: 81277,
      cost: "1,160.00",
      description: "Cytogenomic neo microra alys",
    },
    {
      code: 81283,
      cost: 73.37,
      description: "Ifnl3 gene",
    },
    {
      code: 81284,
      cost: 137,
      description: "Fxn gene detc abnor alleles",
    },
    {
      code: 81285,
      cost: 274.83,
      description: "Fxn gene charac alleles",
    },
    {
      code: 81286,
      cost: 274.83,
      description: "Fxn gene full gene sequence",
    },
    {
      code: 81287,
      cost: 124.64,
      description: "Mgmt gene prmtr mthyltn alys",
    },
    {
      code: 81288,
      cost: 192.32,
      description: "Mlh1 gene",
    },
    {
      code: 81289,
      cost: 185.2,
      description: "Fxn gene known famil variant",
    },
    {
      code: 81290,
      cost: 39.31,
      description: "Mcoln1 gene",
    },
    {
      code: 81291,
      cost: 65.34,
      description: "Mthfr gene",
    },
    {
      code: 81292,
      cost: 675.4,
      description: "Mlh1 gene full seq",
    },
    {
      code: 81293,
      cost: 331,
      description: "Mlh1 gene known variants",
    },
    {
      code: 81294,
      cost: 202.4,
      description: "Mlh1 gene dup/delete variant",
    },
    {
      code: 81295,
      cost: 381.7,
      description: "Msh2 gene full seq",
    },
    {
      code: 81296,
      cost: 337.73,
      description: "Msh2 gene known variants",
    },
    {
      code: 81297,
      cost: 213.3,
      description: "Msh2 gene dup/delete variant",
    },
    {
      code: 81298,
      cost: 641.85,
      description: "Msh6 gene full seq",
    },
    {
      code: 81299,
      cost: 308,
      description: "Msh6 gene known variants",
    },
    {
      code: 81300,
      cost: 238,
      description: "Msh6 gene dup/delete variant",
    },
    {
      code: 81301,
      cost: 348.56,
      description: "Microsatellite instability",
    },
    {
      code: 81302,
      cost: 527.87,
      description: "Mecp2 gene full seq",
    },
    {
      code: 81303,
      cost: 120,
      description: "Mecp2 gene known variant",
    },
    {
      code: 81304,
      cost: 150,
      description: "Mecp2 gene dup/delet variant",
    },
    {
      code: 81305,
      cost: 175.4,
      description: "Myd88 gene p.leu265pro vrnt",
    },
    {
      code: 81306,
      cost: 291.36,
      description: "Nudt15 gene common variants",
    },
    {
      code: 81307,
      cost: 282.88,
      description: "Palb2 gene full gene seq",
    },
    {
      code: 81308,
      cost: 301.35,
      description: "Palb2 gene known famil vrnt",
    },
    {
      code: 81309,
      cost: 274.83,
      description: "Pik3ca gene trgt seq alys",
    },
    {
      code: 81310,
      cost: 246.52,
      description: "Npm1 gene",
    },
    {
      code: 81311,
      cost: 295.79,
      description: "Nras gene variants exon 2&3",
    },
    {
      code: 81312,
      cost: 137,
      description: "Pabpn1 gene detc abnor allel",
    },
    {
      code: 81313,
      cost: 255.05,
      description: "Pca3/klk3 antigen",
    },
    {
      code: 81314,
      cost: 329.51,
      description: "Pdgfra gene",
    },
    {
      code: 81315,
      cost: 207.31,
      description: "Pml/raralpha com breakpoints",
    },
    {
      code: 81316,
      cost: 207.31,
      description: "Pml/raralpha 1 breakpoint",
    },
    {
      code: 81317,
      cost: 676.5,
      description: "Pms2 gene full seq analysis",
    },
    {
      code: 81318,
      cost: 331,
      description: "Pms2 known familial variants",
    },
    {
      code: 81319,
      cost: 203.5,
      description: "Pms2 gene dup/delet variants",
    },
    {
      code: 81320,
      cost: 291.36,
      description: "Plcg2 gene common variants",
    },
    {
      code: 81321,
      cost: 600,
      description: "Pten gene full sequence",
    },
    {
      code: 81322,
      cost: 46.6,
      description: "Pten gene known fam variant",
    },
    {
      code: 81323,
      cost: 300,
      description: "Pten gene dup/delet variant",
    },
    {
      code: 81324,
      cost: 758.36,
      description: "Pmp22 gene dup/delet",
    },
    {
      code: 81325,
      cost: 769.58,
      description: "Pmp22 gene full sequence",
    },
    {
      code: 81326,
      cost: 46.6,
      description: "Pmp22 gene known fam variant",
    },
    {
      code: 81327,
      cost: 192,
      description: "Sept9 gen prmtr mthyltn alys",
    },
    {
      code: 81328,
      cost: 174.81,
      description: "Slco1b1 gene com variants",
    },
    {
      code: 81329,
      cost: 137,
      description: "Smn1 gene dos/deletion alys",
    },
    {
      code: 81330,
      cost: 47,
      description: "Smpd1 gene common variants",
    },
    {
      code: 81331,
      cost: 51.07,
      description: "Snrpn/ube3a gene",
    },
    {
      code: 81332,
      cost: 43.65,
      description: "Serpina1 gene",
    },
    {
      code: 81333,
      cost: 137,
      description: "Tgfbi gene common variants",
    },
    {
      code: 81334,
      cost: 329.51,
      description: "Runx1 gene targeted seq alys",
    },
    {
      code: 81335,
      cost: 174.81,
      description: "Tpmt gene com variants",
    },
    {
      code: 81336,
      cost: 301.35,
      description: "Smn1 gene full gene sequence",
    },
    {
      code: 81337,
      cost: 185.2,
      description: "Smn1 gen nown famil seq vrnt",
    },
    {
      code: 81340,
      cost: 208.92,
      description: "Trb@ gene rearrange amplify",
    },
    {
      code: 81341,
      cost: 49.59,
      description: "Trb@ gene rearrange dirprobe",
    },
    {
      code: 81342,
      cost: 201.5,
      description: "Trg gene rearrangement anal",
    },
    {
      code: 81343,
      cost: 137,
      description: "Ppp2r2b gen detc abnor allel",
    },
    {
      code: 81344,
      cost: 137,
      description: "Tbp gene detc abnor alleles",
    },
    {
      code: 81345,
      cost: 185.2,
      description: "Tert gene targeted seq alys",
    },
    {
      code: 81346,
      cost: 174.81,
      description: "Tyms gene com variants",
    },
    {
      code: 81350,
      cost: 234,
      description: "Ugt1a1 gene common variants",
    },
    {
      code: 81355,
      cost: 88.2,
      description: "Vkorc1 gene",
    },
    {
      code: 81361,
      cost: 174.81,
      description: "Hbb gene com variants",
    },
    {
      code: 81362,
      cost: 375.25,
      description: "Hbb gene known fam variant",
    },
    {
      code: 81363,
      cost: 202.4,
      description: "Hbb gene dup/del variants",
    },
    {
      code: 81364,
      cost: 324.58,
      description: "Hbb full gene sequence",
    },
    {
      code: 81370,
      cost: 402.12,
      description: "Hla i & ii typing lr",
    },
    {
      code: 81371,
      cost: 404.52,
      description: "Hla i & ii type verify lr",
    },
    {
      code: 81372,
      cost: 403.59,
      description: "Hla i typing complete lr",
    },
    {
      code: 81373,
      cost: 127.43,
      description: "Hla i typing 1 locus lr",
    },
    {
      code: 81374,
      cost: 74.33,
      description: "Hla i typing 1 antigen lr",
    },
    {
      code: 81375,
      cost: 220.74,
      description: "Hla ii typing ag equiv lr",
    },
    {
      code: 81376,
      cost: 122.22,
      description: "Hla ii typing 1 locus lr",
    },
    {
      code: 81377,
      cost: 94.74,
      description: "Hla ii type 1 ag equiv lr",
    },
    {
      code: 81378,
      cost: 345.57,
      description: "Hla i & ii typing hr",
    },
    {
      code: 81379,
      cost: 335.38,
      description: "Hla i typing complete hr",
    },
    {
      code: 81380,
      cost: 177.25,
      description: "Hla i typing 1 locus hr",
    },
    {
      code: 81381,
      cost: 169.9,
      description: "Hla i typing 1 allele hr",
    },
    {
      code: 81382,
      cost: 123.68,
      description: "Hla ii typing 1 loc hr",
    },
    {
      code: 81383,
      cost: 109.13,
      description: "Hla ii typing 1 allele hr",
    },
    {
      code: 81400,
      cost: 63.96,
      description: "Mopath procedure level 1",
    },
    {
      code: 81401,
      cost: 137,
      description: "Mopath procedure level 2",
    },
    {
      code: 81402,
      cost: 150.33,
      description: "Mopath procedure level 3",
    },
    {
      code: 81403,
      cost: 185.2,
      description: "Mopath procedure level 4",
    },
    {
      code: 81404,
      cost: 274.83,
      description: "Mopath procedure level 5",
    },
    {
      code: 81405,
      cost: 301.35,
      description: "Mopath procedure level 6",
    },
    {
      code: 81406,
      cost: 282.88,
      description: "Mopath procedure level 7",
    },
    {
      code: 81407,
      cost: 846.27,
      description: "Mopath procedure level 8",
    },
    {
      code: 81408,
      cost: "2,000.00",
      description: "Mopath procedure level 9",
    },
    {
      code: 81410,
      cost: 504,
      description: "Aortic dysfunction/dilation",
    },
    {
      code: 81411,
      cost: "1,350.19",
      description: "Aortic dysfunction/dilation",
    },
    {
      code: 81412,
      cost: "2,448.56",
      description: "Ashkenazi jewish assoc dis",
    },
    {
      code: 81413,
      cost: 584.9,
      description: "Car ion chnnlpath inc 10 gns",
    },
    {
      code: 81414,
      cost: 584.9,
      description: "Car ion chnnlpath inc 2 gns",
    },
    {
      code: 81415,
      cost: "4,780.00",
      description: "Exome sequence analysis",
    },
    {
      code: 81416,
      cost: "12,000.00",
      description: "Exome sequence analysis",
    },
    {
      code: 81417,
      cost: 320,
      description: "Exome re-evaluation",
    },
    {
      code: 81420,
      cost: 759.05,
      description: "Fetal chrmoml aneuploidy",
    },
    {
      code: 81422,
      cost: 759.05,
      description: "Fetal chrmoml microdeltj",
    },
    {
      code: 81425,
      cost: "5,031.20",
      description: "Genome sequence analysis",
    },
    {
      code: 81426,
      cost: "2,709.95",
      description: "Genome sequence analysis",
    },
    {
      code: 81427,
      cost: "2,337.65",
      description: "Genome re-evaluation",
    },
    {
      code: 81430,
      cost: "1,625.00",
      description: "Hearing loss sequence analys",
    },
    {
      code: 81431,
      cost: 679.57,
      description: "Hearing loss dup/del analys",
    },
    {
      code: 81432,
      cost: 679.05,
      description: "Hrdtry brst ca-rlatd dsordrs",
    },
    {
      code: 81433,
      cost: 438.93,
      description: "Hrdtry brst ca-rlatd dsordrs",
    },
    {
      code: 81434,
      cost: 597.91,
      description: "Hereditary retinal disorders",
    },
    {
      code: 81435,
      cost: 584.9,
      description: "Hereditary colon ca dsordrs",
    },
    {
      code: 81436,
      cost: 584.9,
      description: "Hereditary colon ca dsordrs",
    },
    {
      code: 81437,
      cost: 438.93,
      description: "Heredtry nurondcrn tum dsrdr",
    },
    {
      code: 81438,
      cost: 438.93,
      description: "Heredtry nurondcrn tum dsrdr",
    },
    {
      code: 81439,
      cost: 584.9,
      description: "Hrdtry cardmypy gene panel",
    },
    {
      code: 81440,
      cost: "3,324.00",
      description: "Mitochondrial gene",
    },
    {
      code: 81442,
      cost: "2,143.60",
      description: "Noonan spectrum disorders",
    },
    {
      code: 81443,
      cost: "2,448.56",
      description: "Genetic tstg severe inh cond",
    },
    {
      code: 81445,
      cost: 597.91,
      description: "Targeted genomic seq analys",
    },
    {
      code: 81448,
      cost: 584.9,
      description: "Hrdtry perph neurphy panel",
    },
    {
      code: 81450,
      cost: 759.53,
      description: "Targeted genomic seq analys",
    },
    {
      code: 81455,
      cost: "2,919.60",
      description: "Targeted genomic seq analys",
    },
    {
      code: 81460,
      cost: "1,287.00",
      description: "Whole mitochondrial genome",
    },
    {
      code: 81465,
      cost: 936,
      description: "Whole mitochondrial genome",
    },
    {
      code: 81470,
      cost: 914,
      description: "X-linked intellectual dblt",
    },
    {
      code: 81471,
      cost: 914,
      description: "X-linked intellectual dblt",
    },
    {
      code: 81490,
      cost: 840.65,
      description: "Autoimmune rheumatoid arthr",
    },
    {
      code: 81493,
      cost: "1,050.00",
      description: "Cor artery disease mrna",
    },
    {
      code: 81500,
      cost: 260.5,
      description: "Onco (ovar) two proteins",
    },
    {
      code: 81503,
      cost: 897,
      description: "Onco (ovar) five proteins",
    },
    {
      code: 81504,
      cost: 520,
      description: "Oncology tissue of origin",
    },
    {
      code: 81506,
      cost: 68.92,
      description: "Endo assay seven anal",
    },
    {
      code: 81507,
      cost: 795,
      description: "Fetal aneuploidy trisom risk",
    },
    {
      code: 81508,
      cost: 54.3,
      description: "Ftl cgen abnor two proteins",
    },
    {
      code: 81509,
      cost: "1,487.37",
      description: "Ftl cgen abnor 3 proteins",
    },
    {
      code: 81510,
      cost: 55.54,
      description: "Ftl cgen abnor three anal",
    },
    {
      code: 81511,
      cost: 153.5,
      description: "Ftl cgen abnor four anal",
    },
    {
      code: 81512,
      cost: 69.52,
      description: "Ftl cgen abnor five anal",
    },
    {
      code: 81518,
      cost: "3,873.00",
      description: "Onc brst mrna 11 genes",
    },
    {
      code: 81519,
      cost: "3,873.00",
      description: "Oncology breast mrna",
    },
    {
      code: 81520,
      cost: "2,510.21",
      description: "Onc breast mrna 58 genes",
    },
    {
      code: 81521,
      cost: "3,873.00",
      description: "Onc breast mrna 70 genes",
    },
    {
      code: 81522,
      cost: "3,873.00",
      description: "Onc breast mrna 12 genes",
    },
    {
      code: 81525,
      cost: "3,116.00",
      description: "Oncology colon mrna",
    },
    {
      code: 81528,
      cost: 508.87,
      description: "Oncology colorectal scr",
    },
    {
      code: 81535,
      cost: 579.46,
      description: "Oncology gynecologic",
    },
    {
      code: 81536,
      cost: 177.56,
      description: "Oncology gynecologic",
    },
    {
      code: 81538,
      cost: "2,871.00",
      description: "Oncology lung",
    },
    {
      code: 81539,
      cost: 760,
      description: "Oncology prostate prob score",
    },
    {
      code: 81540,
      cost: "3,750.00",
      description: "Oncology tum unknown origin",
    },
    {
      code: 81541,
      cost: "3,873.00",
      description: "Onc prostate mrna 46 genes",
    },
    {
      code: 81542,
      cost: 0,
      description: "Onc prostate mrna 22 cnt gen",
    },
    {
      code: 81545,
      cost: "3,600.00",
      description: "Oncology thyroid",
    },
    {
      code: 81551,
      cost: "2,030.00",
      description: "Onc prostate 3 genes",
    },
    {
      code: 81595,
      cost: "3,240.00",
      description: "Cardiology hrt trnspl mrna",
    },
    {
      code: 81596,
      cost: 72.19,
      description: "Nfct ds chrnc hcv 6 assays",
    },
    {
      code: 82009,
      cost: 4.52,
      description: "Test for acetone/ketones",
    },
    {
      code: 82010,
      cost: 8.17,
      description: "Acetone assay",
    },
    {
      code: 82010,
      cost: 8.17,
      description: "Acetone assay",
    },
    {
      code: 82013,
      cost: 12.29,
      description: "Acetylcholinesterase assay",
    },
    {
      code: 82016,
      cost: 16.49,
      description: "Acylcarnitines qual",
    },
    {
      code: 82017,
      cost: 16.87,
      description: "Acylcarnitines quant",
    },
    {
      code: 82024,
      cost: 38.62,
      description: "Assay of acth",
    },
    {
      code: 82030,
      cost: 25.8,
      description: "Assay of adp & amp",
    },
    {
      code: 82040,
      cost: 4.95,
      description: "Assay of serum albumin",
    },
    {
      code: 82040,
      cost: 4.95,
      description: "Assay of serum albumin",
    },
    {
      code: 82042,
      cost: 7.78,
      description: "Other source albumin quan ea",
    },
    {
      code: 82042,
      cost: 7.78,
      description: "Other source albumin quan ea",
    },
    {
      code: 82043,
      cost: 5.78,
      description: "Ur albumin quantitative",
    },
    {
      code: 82043,
      cost: 5.78,
      description: "Ur albumin quantitative",
    },
    {
      code: 82044,
      cost: 6.23,
      description: "Ur albumin semiquantitative",
    },
    {
      code: 82044,
      cost: 6.23,
      description: "Ur albumin semiquantitative",
    },
    {
      code: 82045,
      cost: 33.94,
      description: "Albumin ischemia modified",
    },
    {
      code: 82075,
      cost: 30,
      description: "Assay of breath ethanol",
    },
    {
      code: 82085,
      cost: 9.71,
      description: "Assay of aldolase",
    },
    {
      code: 82088,
      cost: 40.75,
      description: "Assay of aldosterone",
    },
    {
      code: 82103,
      cost: 13.44,
      description: "Alpha-1-antitrypsin total",
    },
    {
      code: 82104,
      cost: 14.46,
      description: "Alpha-1-antitrypsin pheno",
    },
    {
      code: 82105,
      cost: 16.77,
      description: "Alpha-fetoprotein serum",
    },
    {
      code: 82106,
      cost: 17,
      description: "Alpha-fetoprotein amniotic",
    },
    {
      code: 82107,
      cost: 64.41,
      description: "Alpha-fetoprotein l3",
    },
    {
      code: 82108,
      cost: 25.48,
      description: "Assay of aluminum",
    },
    {
      code: 82120,
      cost: 5.99,
      description: "Amines vaginal fluid qual",
    },
    {
      code: 82120,
      cost: 5.99,
      description: "Amines vaginal fluid qual",
    },
    {
      code: 82127,
      cost: 14.18,
      description: "Amino acid single qual",
    },
    {
      code: 82128,
      cost: 13.87,
      description: "Amino acids mult qual",
    },
    {
      code: 82131,
      cost: 22.98,
      description: "Amino acids single quant",
    },
    {
      code: 82135,
      cost: 16.45,
      description: "Assay aminolevulinic acid",
    },
    {
      code: 82136,
      cost: 19.61,
      description: "Amino acids quant 2-5",
    },
    {
      code: 82139,
      cost: 16.87,
      description: "Amino acids quan 6 or more",
    },
    {
      code: 82140,
      cost: 14.57,
      description: "Assay of ammonia",
    },
    {
      code: 82143,
      cost: 9.35,
      description: "Amniotic fluid scan",
    },
    {
      code: 82150,
      cost: 6.48,
      description: "Assay of amylase",
    },
    {
      code: 82150,
      cost: 6.48,
      description: "Assay of amylase",
    },
    {
      code: 82154,
      cost: 28.83,
      description: "Androstanediol glucuronide",
    },
    {
      code: 82157,
      cost: 29.28,
      description: "Assay of androstenedione",
    },
    {
      code: 82160,
      cost: 25.55,
      description: "Assay of androsterone",
    },
    {
      code: 82163,
      cost: 20.52,
      description: "Assay of angiotensin ii",
    },
    {
      code: 82164,
      cost: 14.6,
      description: "Angiotensin i enzyme test",
    },
    {
      code: 82172,
      cost: 21.09,
      description: "Assay of apolipoprotein",
    },
    {
      code: 82175,
      cost: 18.97,
      description: "Assay of arsenic",
    },
    {
      code: 82180,
      cost: 9.89,
      description: "Assay of ascorbic acid",
    },
    {
      code: 82190,
      cost: 15.9,
      description: "Atomic absorption",
    },
    {
      code: 82232,
      cost: 16.18,
      description: "Assay of beta-2 protein",
    },
    {
      code: 82239,
      cost: 17.12,
      description: "Bile acids total",
    },
    {
      code: 82240,
      cost: 26.58,
      description: "Bile acids cholylglycine",
    },
    {
      code: 82247,
      cost: 5.02,
      description: "Bilirubin total",
    },
    {
      code: 82247,
      cost: 5.02,
      description: "Bilirubin total",
    },
    {
      code: 82248,
      cost: 5.02,
      description: "Bilirubin direct",
    },
    {
      code: 82252,
      cost: 4.56,
      description: "Fecal bilirubin test",
    },
    {
      code: 82261,
      cost: 16.87,
      description: "Assay of biotinidase",
    },
    {
      code: 82270,
      cost: 4.38,
      description: "Occult blood feces",
    },
    {
      code: 82271,
      cost: 5.32,
      description: "Occult blood other sources",
    },
    {
      code: 82271,
      cost: 5.32,
      description: "Occult blood other sources",
    },
    {
      code: 82272,
      cost: 4.23,
      description: "Occult bld feces 1-3 tests",
    },
    {
      code: 82274,
      cost: 15.92,
      description: "Assay test for blood fecal",
    },
    {
      code: 82274,
      cost: 15.92,
      description: "Assay test for blood fecal",
    },
    {
      code: 82286,
      cost: 5.16,
      description: "Assay of bradykinin",
    },
    {
      code: 82300,
      cost: 23.64,
      description: "Assay of cadmium",
    },
    {
      code: 82306,
      cost: 29.6,
      description: "Vitamin d 25 hydroxy",
    },
    {
      code: 82308,
      cost: 26.79,
      description: "Assay of calcitonin",
    },
    {
      code: 82310,
      cost: 5.16,
      description: "Assay of calcium",
    },
    {
      code: 82310,
      cost: 5.16,
      description: "Assay of calcium",
    },
    {
      code: 82330,
      cost: 13.68,
      description: "Assay of calcium",
    },
    {
      code: 82330,
      cost: 13.68,
      description: "Assay of calcium",
    },
    {
      code: 82331,
      cost: 13.34,
      description: "Calcium infusion test",
    },
    {
      code: 82340,
      cost: 6.03,
      description: "Assay of calcium in urine",
    },
    {
      code: 82355,
      cost: 11.58,
      description: "Calculus analysis qual",
    },
    {
      code: 82360,
      cost: 12.87,
      description: "Calculus assay quant",
    },
    {
      code: 82365,
      cost: 12.9,
      description: "Calculus spectroscopy",
    },
    {
      code: 82370,
      cost: 12.52,
      description: "X-ray assay calculus",
    },
    {
      code: 82373,
      cost: 18.06,
      description: "Assay c-d transfer measure",
    },
    {
      code: 82374,
      cost: 4.88,
      description: "Assay blood carbon dioxide",
    },
    {
      code: 82374,
      cost: 4.88,
      description: "Assay blood carbon dioxide",
    },
    {
      code: 82375,
      cost: 12.32,
      description: "Assay carboxyhb quant",
    },
    {
      code: 82376,
      cost: 14.07,
      description: "Assay carboxyhb qual",
    },
    {
      code: 82378,
      cost: 18.96,
      description: "Carcinoembryonic antigen",
    },
    {
      code: 82379,
      cost: 16.87,
      description: "Assay of carnitine",
    },
    {
      code: 82380,
      cost: 9.22,
      description: "Assay of carotene",
    },
    {
      code: 82382,
      cost: 27.3,
      description: "Assay urine catecholamines",
    },
    {
      code: 82383,
      cost: 29.08,
      description: "Assay blood catecholamines",
    },
    {
      code: 82384,
      cost: 25.25,
      description: "Assay three catecholamines",
    },
    {
      code: 82387,
      cost: 18.06,
      description: "Assay of cathepsin-d",
    },
    {
      code: 82390,
      cost: 10.74,
      description: "Assay of ceruloplasmin",
    },
    {
      code: 82397,
      cost: 14.12,
      description: "Chemiluminescent assay",
    },
    {
      code: 82415,
      cost: 12.67,
      description: "Assay of chloramphenicol",
    },
    {
      code: 82435,
      cost: 4.6,
      description: "Assay of blood chloride",
    },
    {
      code: 82435,
      cost: 4.6,
      description: "Assay of blood chloride",
    },
    {
      code: 82436,
      cost: 5.75,
      description: "Assay of urine chloride",
    },
    {
      code: 82438,
      cost: 5,
      description: "Assay other fluid chlorides",
    },
    {
      code: 82441,
      cost: 6.01,
      description: "Test for chlorohydrocarbons",
    },
    {
      code: 82465,
      cost: 4.35,
      description: "Assay bld/serum cholesterol",
    },
    {
      code: 82465,
      cost: 4.35,
      description: "Assay bld/serum cholesterol",
    },
    {
      code: 82480,
      cost: 7.87,
      description: "Assay serum cholinesterase",
    },
    {
      code: 82482,
      cost: 9.81,
      description: "Assay rbc cholinesterase",
    },
    {
      code: 82485,
      cost: 20.65,
      description: "Assay chondroitin sulfate",
    },
    {
      code: 82495,
      cost: 20.28,
      description: "Assay of chromium",
    },
    {
      code: 82507,
      cost: 27.8,
      description: "Assay of citrate",
    },
    {
      code: 82523,
      cost: 18.68,
      description: "Collagen crosslinks",
    },
    {
      code: 82523,
      cost: 18.68,
      description: "Collagen crosslinks",
    },
    {
      code: 82525,
      cost: 12.41,
      description: "Assay of copper",
    },
    {
      code: 82528,
      cost: 22.52,
      description: "Assay of corticosterone",
    },
    {
      code: 82530,
      cost: 16.71,
      description: "Cortisol free",
    },
    {
      code: 82533,
      cost: 16.3,
      description: "Total cortisol",
    },
    {
      code: 82540,
      cost: 4.64,
      description: "Assay of creatine",
    },
    {
      code: 82542,
      cost: 24.09,
      description: "Col chromotography qual/quan",
    },
    {
      code: 82550,
      cost: 6.51,
      description: "Assay of ck (cpk)",
    },
    {
      code: 82550,
      cost: 6.51,
      description: "Assay of ck (cpk)",
    },
    {
      code: 82552,
      cost: 13.39,
      description: "Assay of cpk in blood",
    },
    {
      code: 82553,
      cost: 11.55,
      description: "Creatine mb fraction",
    },
    {
      code: 82554,
      cost: 11.87,
      description: "Creatine isoforms",
    },
    {
      code: 82565,
      cost: 5.12,
      description: "Assay of creatinine",
    },
    {
      code: 82565,
      cost: 5.12,
      description: "Assay of creatinine",
    },
    {
      code: 82570,
      cost: 5.18,
      description: "Assay of urine creatinine",
    },
    {
      code: 82570,
      cost: 5.18,
      description: "Assay of urine creatinine",
    },
    {
      code: 82575,
      cost: 9.46,
      description: "Creatinine clearance test",
    },
    {
      code: 82585,
      cost: 14.14,
      description: "Assay of cryofibrinogen",
    },
    {
      code: 82595,
      cost: 6.47,
      description: "Assay of cryoglobulin",
    },
    {
      code: 82600,
      cost: 19.4,
      description: "Assay of cyanide",
    },
    {
      code: 82607,
      cost: 15.08,
      description: "Vitamin b-12",
    },
    {
      code: 82608,
      cost: 14.32,
      description: "B-12 binding capacity",
    },
    {
      code: 82610,
      cost: 18.52,
      description: "Cystatin c",
    },
    {
      code: 82615,
      cost: 9.55,
      description: "Test for urine cystines",
    },
    {
      code: 82626,
      cost: 25.27,
      description: "Dehydroepiandrosterone",
    },
    {
      code: 82627,
      cost: 22.23,
      description: "Dehydroepiandrosterone",
    },
    {
      code: 82633,
      cost: 30.98,
      description: "Desoxycorticosterone",
    },
    {
      code: 82634,
      cost: 29.28,
      description: "Deoxycortisol",
    },
    {
      code: 82638,
      cost: 12.25,
      description: "Assay of dibucaine number",
    },
    {
      code: 82642,
      cost: 29.28,
      description: "Dihydrotestosterone",
    },
    {
      code: 82652,
      cost: 38.5,
      description: "Vit d 1 25-dihydroxy",
    },
    {
      code: 82656,
      cost: 11.53,
      description: "Pancreatic elastase fecal",
    },
    {
      code: 82657,
      cost: 22.17,
      description: "Enzyme cell activity",
    },
    {
      code: 82658,
      cost: 44.03,
      description: "Enzyme cell activity ra",
    },
    {
      code: 82664,
      cost: 61.5,
      description: "Electrophoretic test",
    },
    {
      code: 82668,
      cost: 18.79,
      description: "Assay of erythropoietin",
    },
    {
      code: 82670,
      cost: 27.94,
      description: "Assay of estradiol",
    },
    {
      code: 82671,
      cost: 32.3,
      description: "Assay of estrogens",
    },
    {
      code: 82672,
      cost: 21.7,
      description: "Assay of estrogen",
    },
    {
      code: 82677,
      cost: 24.18,
      description: "Assay of estriol",
    },
    {
      code: 82679,
      cost: 24.95,
      description: "Assay of estrone",
    },
    {
      code: 82679,
      cost: 24.95,
      description: "Assay of estrone",
    },
    {
      code: 82693,
      cost: 14.9,
      description: "Assay of ethylene glycol",
    },
    {
      code: 82696,
      cost: 26.24,
      description: "Assay of etiocholanolone",
    },
    {
      code: 82705,
      cost: 5.1,
      description: "Fats/lipids feces qual",
    },
    {
      code: 82710,
      cost: 16.8,
      description: "Fats/lipids feces quant",
    },
    {
      code: 82715,
      cost: 22.97,
      description: "Assay of fecal fat",
    },
    {
      code: 82725,
      cost: 18.77,
      description: "Assay of blood fatty acids",
    },
    {
      code: 82726,
      cost: 19.75,
      description: "Long chain fatty acids",
    },
    {
      code: 82728,
      cost: 13.63,
      description: "Assay of ferritin",
    },
    {
      code: 82731,
      cost: 64.41,
      description: "Assay of fetal fibronectin",
    },
    {
      code: 82735,
      cost: 18.54,
      description: "Assay of fluoride",
    },
    {
      code: 82746,
      cost: 14.7,
      description: "Assay of folic acid serum",
    },
    {
      code: 82747,
      cost: 17.65,
      description: "Assay of folic acid rbc",
    },
    {
      code: 82757,
      cost: 17.34,
      description: "Assay of semen fructose",
    },
    {
      code: 82759,
      cost: 21.48,
      description: "Assay of rbc galactokinase",
    },
    {
      code: 82760,
      cost: 11.2,
      description: "Assay of galactose",
    },
    {
      code: 82775,
      cost: 21.07,
      description: "Assay galactose transferase",
    },
    {
      code: 82776,
      cost: 11.74,
      description: "Galactose transferase test",
    },
    {
      code: 82777,
      cost: 44.25,
      description: "Galectin-3",
    },
    {
      code: 82784,
      cost: 9.3,
      description: "Assay iga/igd/igg/igm each",
    },
    {
      code: 82785,
      cost: 16.46,
      description: "Assay of ige",
    },
    {
      code: 82787,
      cost: 8.02,
      description: "Igg 1 2 3 or 4 each",
    },
    {
      code: 82800,
      cost: 11,
      description: "Blood ph",
    },
    {
      code: 82803,
      cost: 26.07,
      description: "Blood gases any combination",
    },
    {
      code: 82805,
      cost: 78.77,
      description: "Blood gases w/o2 saturation",
    },
    {
      code: 82810,
      cost: 9.77,
      description: "Blood gases o2 sat only",
    },
    {
      code: 82820,
      cost: 13.34,
      description: "Hemoglobin-oxygen affinity",
    },
    {
      code: 82930,
      cost: 6.71,
      description: "Gastric analy w/ph ea spec",
    },
    {
      code: 82938,
      cost: 17.69,
      description: "Gastrin test",
    },
    {
      code: 82941,
      cost: 17.63,
      description: "Assay of gastrin",
    },
    {
      code: 82943,
      cost: 14.29,
      description: "Assay of glucagon",
    },
    {
      code: 82945,
      cost: 3.93,
      description: "Glucose other fluid",
    },
    {
      code: 82946,
      cost: 17.77,
      description: "Glucagon tolerance test",
    },
    {
      code: 82947,
      cost: 3.93,
      description: "Assay glucose blood quant",
    },
    {
      code: 82947,
      cost: 3.93,
      description: "Assay glucose blood quant",
    },
    {
      code: 82948,
      cost: 5.04,
      description: "Reagent strip/blood glucose",
    },
    {
      code: 82950,
      cost: 4.75,
      description: "Glucose test",
    },
    {
      code: 82950,
      cost: 4.75,
      description: "Glucose test",
    },
    {
      code: 82951,
      cost: 12.87,
      description: "Glucose tolerance test (gtt)",
    },
    {
      code: 82951,
      cost: 12.87,
      description: "Glucose tolerance test (gtt)",
    },
    {
      code: 82952,
      cost: 3.92,
      description: "Gtt-added samples",
    },
    {
      code: 82952,
      cost: 3.92,
      description: "Gtt-added samples",
    },
    {
      code: 82955,
      cost: 9.7,
      description: "Assay of g6pd enzyme",
    },
    {
      code: 82960,
      cost: 6.05,
      description: "Test for g6pd enzyme",
    },
    {
      code: 82962,
      cost: 3.28,
      description: "Glucose blood test",
    },
    {
      code: 82963,
      cost: 21.48,
      description: "Assay of glucosidase",
    },
    {
      code: 82965,
      cost: 13.15,
      description: "Assay of gdh enzyme",
    },
    {
      code: 82977,
      cost: 7.2,
      description: "Assay of ggt",
    },
    {
      code: 82977,
      cost: 7.2,
      description: "Assay of ggt",
    },
    {
      code: 82978,
      cost: 15.45,
      description: "Assay of glutathione",
    },
    {
      code: 82979,
      cost: 9.44,
      description: "Assay rbc glutathione",
    },
    {
      code: 82985,
      cost: 16.76,
      description: "Assay of glycated protein",
    },
    {
      code: 82985,
      cost: 16.76,
      description: "Assay of glycated protein",
    },
    {
      code: 83001,
      cost: 18.58,
      description: "Assay of gonadotropin (fsh)",
    },
    {
      code: 83001,
      cost: 18.58,
      description: "Assay of gonadotropin (fsh)",
    },
    {
      code: 83002,
      cost: 18.52,
      description: "Assay of gonadotropin (lh)",
    },
    {
      code: 83002,
      cost: 18.52,
      description: "Assay of gonadotropin (lh)",
    },
    {
      code: 83003,
      cost: 16.67,
      description: "Assay growth hormone (hgh)",
    },
    {
      code: 83006,
      cost: 75.6,
      description: "Growth stimulation gene 2",
    },
    {
      code: 83009,
      cost: 67.36,
      description: "H pylori (c-13) blood",
    },
    {
      code: 83010,
      cost: 12.58,
      description: "Assay of haptoglobin quant",
    },
    {
      code: 83012,
      cost: 26.89,
      description: "Assay of haptoglobins",
    },
    {
      code: 83013,
      cost: 67.36,
      description: "H pylori (c-13) breath",
    },
    {
      code: 83014,
      cost: 7.86,
      description: "H pylori drug admin",
    },
    {
      code: 83015,
      cost: 20.94,
      description: "Heavy metal qual any anal",
    },
    {
      code: 83018,
      cost: 21.96,
      description: "Heavy metal quant each nes",
    },
    {
      code: 83020,
      cost: 12.87,
      description: "Hemoglobin electrophoresis",
    },
    {
      code: 83021,
      cost: 18.06,
      description: "Hemoglobin chromotography",
    },
    {
      code: 83026,
      cost: 4.01,
      description: "Hemoglobin copper sulfate",
    },
    {
      code: 83030,
      cost: 10.74,
      description: "Fetal hemoglobin chemical",
    },
    {
      code: 83033,
      cost: 8,
      description: "Fetal hemoglobin assay qual",
    },
    {
      code: 83036,
      cost: 9.71,
      description: "Glycosylated hemoglobin test",
    },
    {
      code: 83036,
      cost: 9.71,
      description: "Glycosylated hemoglobin test",
    },
    {
      code: 83037,
      cost: 9.71,
      description: "Glycosylated hb home device",
    },
    {
      code: 83037,
      cost: 9.71,
      description: "Glycosylated hb home device",
    },
    {
      code: 83045,
      cost: 6.49,
      description: "Blood methemoglobin test",
    },
    {
      code: 83050,
      cost: 8.2,
      description: "Blood methemoglobin assay",
    },
    {
      code: 83051,
      cost: 7.31,
      description: "Assay of plasma hemoglobin",
    },
    {
      code: 83060,
      cost: 8.8,
      description: "Blood sulfhemoglobin assay",
    },
    {
      code: 83065,
      cost: 9,
      description: "Assay of hemoglobin heat",
    },
    {
      code: 83068,
      cost: 9.47,
      description: "Hemoglobin stability screen",
    },
    {
      code: 83069,
      cost: 3.95,
      description: "Assay of urine hemoglobin",
    },
    {
      code: 83070,
      cost: 4.75,
      description: "Assay of hemosiderin qual",
    },
    {
      code: 83080,
      cost: 16.87,
      description: "Assay of b hexosaminidase",
    },
    {
      code: 83088,
      cost: 29.53,
      description: "Assay of histamine",
    },
    {
      code: 83090,
      cost: 17.92,
      description: "Assay of homocystine",
    },
    {
      code: 83150,
      cost: 22.41,
      description: "Assay of homovanillic acid",
    },
    {
      code: 83491,
      cost: 17.9,
      description: "Assay of corticosteroids 17",
    },
    {
      code: 83497,
      cost: 12.9,
      description: "Assay of 5-hiaa",
    },
    {
      code: 83498,
      cost: 27.17,
      description: "Assay of progesterone 17-d",
    },
    {
      code: 83500,
      cost: 22.65,
      description: "Assay free hydroxyproline",
    },
    {
      code: 83505,
      cost: 24.3,
      description: "Assay total hydroxyproline",
    },
    {
      code: 83516,
      cost: 11.53,
      description: "Immunoassay nonantibody",
    },
    {
      code: 83516,
      cost: 11.53,
      description: "Immunoassay nonantibody",
    },
    {
      code: 83518,
      cost: 9.64,
      description: "Immunoassay dipstick",
    },
    {
      code: 83518,
      cost: 9.64,
      description: "Immunoassay dipstick",
    },
    {
      code: 83519,
      cost: 18.4,
      description: "Ria nonantibody",
    },
    {
      code: 83520,
      cost: 17.27,
      description: "Immunoassay quant nos nonab",
    },
    {
      code: 83520,
      cost: 17.27,
      description: "Immunoassay quant nos nonab",
    },
    {
      code: 83525,
      cost: 11.43,
      description: "Assay of insulin",
    },
    {
      code: 83527,
      cost: 12.95,
      description: "Assay of insulin",
    },
    {
      code: 83528,
      cost: 19.82,
      description: "Assay of intrinsic factor",
    },
    {
      code: 83540,
      cost: 6.47,
      description: "Assay of iron",
    },
    {
      code: 83550,
      cost: 8.74,
      description: "Iron binding test",
    },
    {
      code: 83570,
      cost: 8.85,
      description: "Assay of idh enzyme",
    },
    {
      code: 83582,
      cost: 15.47,
      description: "Assay of ketogenic steroids",
    },
    {
      code: 83586,
      cost: 12.8,
      description: "Assay 17- ketosteroids",
    },
    {
      code: 83593,
      cost: 28.5,
      description: "Fractionation ketosteroids",
    },
    {
      code: 83605,
      cost: 11.57,
      description: "Assay of lactic acid",
    },
    {
      code: 83605,
      cost: 11.57,
      description: "Assay of lactic acid",
    },
    {
      code: 83615,
      cost: 6.04,
      description: "Lactate (ld) (ldh) enzyme",
    },
    {
      code: 83625,
      cost: 12.79,
      description: "Assay of ldh enzymes",
    },
    {
      code: 83630,
      cost: 19.7,
      description: "Lactoferrin fecal (qual)",
    },
    {
      code: 83631,
      cost: 19.63,
      description: "Lactoferrin fecal (quant)",
    },
    {
      code: 83632,
      cost: 20.22,
      description: "Placental lactogen",
    },
    {
      code: 83633,
      cost: 11.25,
      description: "Test urine for lactose",
    },
    {
      code: 83655,
      cost: 12.11,
      description: "Assay of lead",
    },
    {
      code: 83655,
      cost: 12.11,
      description: "Assay of lead",
    },
    {
      code: 83661,
      cost: 21.99,
      description: "L/s ratio fetal lung",
    },
    {
      code: 83662,
      cost: 18.91,
      description: "Foam stability fetal lung",
    },
    {
      code: 83663,
      cost: 18.91,
      description: "Fluoro polarize fetal lung",
    },
    {
      code: 83664,
      cost: 19.32,
      description: "Lamellar bdy fetal lung",
    },
    {
      code: 83670,
      cost: 9.81,
      description: "Assay of lap enzyme",
    },
    {
      code: 83690,
      cost: 6.89,
      description: "Assay of lipase",
    },
    {
      code: 83695,
      cost: 14.32,
      description: "Assay of lipoprotein(a)",
    },
    {
      code: 83698,
      cost: 46.31,
      description: "Assay lipoprotein pla2",
    },
    {
      code: 83700,
      cost: 11.26,
      description: "Lipopro bld electrophoretic",
    },
    {
      code: 83701,
      cost: 33.86,
      description: "Lipoprotein bld hr fraction",
    },
    {
      code: 83704,
      cost: 34.19,
      description: "Lipoprotein bld quan part",
    },
    {
      code: 83718,
      cost: 8.19,
      description: "Assay of lipoprotein",
    },
    {
      code: 83718,
      cost: 8.19,
      description: "Assay of lipoprotein",
    },
    {
      code: 83719,
      cost: 12.75,
      description: "Assay of blood lipoprotein",
    },
    {
      code: 83721,
      cost: 10.5,
      description: "Assay of blood lipoprotein",
    },
    {
      code: 83721,
      cost: 10.5,
      description: "Assay of blood lipoprotein",
    },
    {
      code: 83722,
      cost: 34.19,
      description: "Lipoprtn dir meas sd ldl chl",
    },
    {
      code: 83727,
      cost: 17.19,
      description: "Assay of lrh hormone",
    },
    {
      code: 83735,
      cost: 6.7,
      description: "Assay of magnesium",
    },
    {
      code: 83775,
      cost: 7.37,
      description: "Assay malate dehydrogenase",
    },
    {
      code: 83785,
      cost: 26.65,
      description: "Assay of manganese",
    },
    {
      code: 83789,
      cost: 24.11,
      description: "Mass spectrometry qual/quan",
    },
    {
      code: 83825,
      cost: 16.26,
      description: "Assay of mercury",
    },
    {
      code: 83835,
      cost: 16.94,
      description: "Assay of metanephrines",
    },
    {
      code: 83857,
      cost: 10.74,
      description: "Assay of methemalbumin",
    },
    {
      code: 83861,
      cost: 22.48,
      description: "Microfluid analy tears",
    },
    {
      code: 83861,
      cost: 22.48,
      description: "Microfluid analy tears",
    },
    {
      code: 83864,
      cost: 28.5,
      description: "Mucopolysaccharides",
    },
    {
      code: 83872,
      cost: 5.86,
      description: "Assay synovial fluid mucin",
    },
    {
      code: 83873,
      cost: 17.2,
      description: "Assay of csf protein",
    },
    {
      code: 83874,
      cost: 12.92,
      description: "Assay of myoglobin",
    },
    {
      code: 83876,
      cost: 50.86,
      description: "Assay myeloperoxidase",
    },
    {
      code: 83880,
      cost: 39.26,
      description: "Assay of natriuretic peptide",
    },
    {
      code: 83880,
      cost: 39.26,
      description: "Assay of natriuretic peptide",
    },
    {
      code: 83883,
      cost: 13.6,
      description: "Assay nephelometry not spec",
    },
    {
      code: 83885,
      cost: 24.51,
      description: "Assay of nickel",
    },
    {
      code: 83915,
      cost: 11.15,
      description: "Assay of nucleotidase",
    },
    {
      code: 83916,
      cost: 27.39,
      description: "Oligoclonal bands",
    },
    {
      code: 83918,
      cost: 23.6,
      description: "Organic acids total quant",
    },
    {
      code: 83919,
      cost: 16.45,
      description: "Organic acids qual each",
    },
    {
      code: 83921,
      cost: 21.21,
      description: "Organic acid single quant",
    },
    {
      code: 83930,
      cost: 6.61,
      description: "Assay of blood osmolality",
    },
    {
      code: 83935,
      cost: 6.82,
      description: "Assay of urine osmolality",
    },
    {
      code: 83937,
      cost: 29.85,
      description: "Assay of osteocalcin",
    },
    {
      code: 83945,
      cost: 14.45,
      description: "Assay of oxalate",
    },
    {
      code: 83950,
      cost: 64.41,
      description: "Oncoprotein her-2/neu",
    },
    {
      code: 83951,
      cost: 64.41,
      description: "Oncoprotein dcp",
    },
    {
      code: 83970,
      cost: 41.28,
      description: "Assay of parathormone",
    },
    {
      code: 83986,
      cost: 3.58,
      description: "Assay ph body fluid nos",
    },
    {
      code: 83986,
      cost: 3.58,
      description: "Assay ph body fluid nos",
    },
    {
      code: 83987,
      cost: 3.58,
      description: "Exhaled breath condensate",
    },
    {
      code: 83993,
      cost: 19.63,
      description: "Assay for calprotectin fecal",
    },
    {
      code: 84030,
      cost: 5.5,
      description: "Assay of blood pku",
    },
    {
      code: 84035,
      cost: 3.98,
      description: "Assay of phenylketones",
    },
    {
      code: 84060,
      cost: 7.64,
      description: "Assay acid phosphatase",
    },
    {
      code: 84066,
      cost: 9.66,
      description: "Assay prostate phosphatase",
    },
    {
      code: 84075,
      cost: 5.18,
      description: "Assay alkaline phosphatase",
    },
    {
      code: 84075,
      cost: 5.18,
      description: "Assay alkaline phosphatase",
    },
    {
      code: 84078,
      cost: 8.26,
      description: "Assay alkaline phosphatase",
    },
    {
      code: 84080,
      cost: 14.78,
      description: "Assay alkaline phosphatases",
    },
    {
      code: 84081,
      cost: 16.52,
      description: "Assay phosphatidylglycerol",
    },
    {
      code: 84085,
      cost: 9.44,
      description: "Assay of rbc pg6d enzyme",
    },
    {
      code: 84087,
      cost: 10.73,
      description: "Assay phosphohexose enzymes",
    },
    {
      code: 84100,
      cost: 4.74,
      description: "Assay of phosphorus",
    },
    {
      code: 84105,
      cost: 5.78,
      description: "Assay of urine phosphorus",
    },
    {
      code: 84106,
      cost: 5.82,
      description: "Test for porphobilinogen",
    },
    {
      code: 84110,
      cost: 8.44,
      description: "Assay of porphobilinogen",
    },
    {
      code: 84112,
      cost: 98.11,
      description: "Eval amniotic fluid protein",
    },
    {
      code: 84119,
      cost: 13.36,
      description: "Test urine for porphyrins",
    },
    {
      code: 84120,
      cost: 14.71,
      description: "Assay of urine porphyrins",
    },
    {
      code: 84126,
      cost: 39.11,
      description: "Assay of feces porphyrins",
    },
    {
      code: 84132,
      cost: 4.76,
      description: "Assay of serum potassium",
    },
    {
      code: 84132,
      cost: 4.76,
      description: "Assay of serum potassium",
    },
    {
      code: 84133,
      cost: 4.73,
      description: "Assay of urine potassium",
    },
    {
      code: 84134,
      cost: 14.59,
      description: "Assay of prealbumin",
    },
    {
      code: 84135,
      cost: 21.27,
      description: "Assay of pregnanediol",
    },
    {
      code: 84138,
      cost: 21.05,
      description: "Assay of pregnanetriol",
    },
    {
      code: 84140,
      cost: 20.67,
      description: "Assay of pregnenolone",
    },
    {
      code: 84143,
      cost: 22.81,
      description: "Assay of 17-hydroxypregneno",
    },
    {
      code: 84144,
      cost: 20.86,
      description: "Assay of progesterone",
    },
    {
      code: 84145,
      cost: 27.22,
      description: "Procalcitonin (pct)",
    },
    {
      code: 84146,
      cost: 19.38,
      description: "Assay of prolactin",
    },
    {
      code: 84150,
      cost: 41.77,
      description: "Assay of prostaglandin",
    },
    {
      code: 84152,
      cost: 18.39,
      description: "Assay of psa complexed",
    },
    {
      code: 84153,
      cost: 18.39,
      description: "Assay of psa total",
    },
    {
      code: 84154,
      cost: 18.39,
      description: "Assay of psa free",
    },
    {
      code: 84155,
      cost: 3.67,
      description: "Assay of protein serum",
    },
    {
      code: 84155,
      cost: 3.67,
      description: "Assay of protein serum",
    },
    {
      code: 84156,
      cost: 3.67,
      description: "Assay of protein urine",
    },
    {
      code: 84157,
      cost: 4,
      description: "Assay of protein other",
    },
    {
      code: 84157,
      cost: 4,
      description: "Assay of protein other",
    },
    {
      code: 84160,
      cost: 5.61,
      description: "Assay of protein any source",
    },
    {
      code: 84163,
      cost: 15.05,
      description: "Pappa serum",
    },
    {
      code: 84165,
      cost: 10.74,
      description: "Protein e-phoresis serum",
    },
    {
      code: 84166,
      cost: 17.83,
      description: "Protein e-phoresis/urine/csf",
    },
    {
      code: 84181,
      cost: 17.03,
      description: "Western blot test",
    },
    {
      code: 84182,
      cost: 29.21,
      description: "Protein western blot test",
    },
    {
      code: 84202,
      cost: 14.35,
      description: "Assay rbc protoporphyrin",
    },
    {
      code: 84203,
      cost: 9.74,
      description: "Test rbc protoporphyrin",
    },
    {
      code: 84206,
      cost: 26.69,
      description: "Assay of proinsulin",
    },
    {
      code: 84207,
      cost: 28.1,
      description: "Assay of vitamin b-6",
    },
    {
      code: 84210,
      cost: 14.48,
      description: "Assay of pyruvate",
    },
    {
      code: 84220,
      cost: 9.44,
      description: "Assay of pyruvate kinase",
    },
    {
      code: 84228,
      cost: 11.63,
      description: "Assay of quinine",
    },
    {
      code: 84233,
      cost: 87.88,
      description: "Assay of estrogen",
    },
    {
      code: 84234,
      cost: 64.88,
      description: "Assay of progesterone",
    },
    {
      code: 84235,
      cost: 71.23,
      description: "Assay of endocrine hormone",
    },
    {
      code: 84238,
      cost: 36.57,
      description: "Assay nonendocrine receptor",
    },
    {
      code: 84244,
      cost: 21.99,
      description: "Assay of renin",
    },
    {
      code: 84252,
      cost: 20.24,
      description: "Assay of vitamin b-2",
    },
    {
      code: 84255,
      cost: 25.53,
      description: "Assay of selenium",
    },
    {
      code: 84260,
      cost: 30.98,
      description: "Assay of serotonin",
    },
    {
      code: 84270,
      cost: 21.73,
      description: "Assay of sex hormone globul",
    },
    {
      code: 84275,
      cost: 13.44,
      description: "Assay of sialic acid",
    },
    {
      code: 84285,
      cost: 25.21,
      description: "Assay of silica",
    },
    {
      code: 84295,
      cost: 4.81,
      description: "Assay of serum sodium",
    },
    {
      code: 84295,
      cost: 4.81,
      description: "Assay of serum sodium",
    },
    {
      code: 84300,
      cost: 5.06,
      description: "Assay of urine sodium",
    },
    {
      code: 84302,
      cost: 4.86,
      description: "Assay of sweat sodium",
    },
    {
      code: 84305,
      cost: 21.26,
      description: "Assay of somatomedin",
    },
    {
      code: 84307,
      cost: 18.28,
      description: "Assay of somatostatin",
    },
    {
      code: 84311,
      cost: 8.1,
      description: "Spectrophotometry",
    },
    {
      code: 84315,
      cost: 3.28,
      description: "Body fluid specific gravity",
    },
    {
      code: 84375,
      cost: 39,
      description: "Chromatogram assay sugars",
    },
    {
      code: 84376,
      cost: 5.5,
      description: "Sugars single qual",
    },
    {
      code: 84377,
      cost: 5.5,
      description: "Sugars multiple qual",
    },
    {
      code: 84378,
      cost: 11.53,
      description: "Sugars single quant",
    },
    {
      code: 84379,
      cost: 11.53,
      description: "Sugars multiple quant",
    },
    {
      code: 84392,
      cost: 5.49,
      description: "Assay of urine sulfate",
    },
    {
      code: 84402,
      cost: 25.47,
      description: "Assay of free testosterone",
    },
    {
      code: 84403,
      cost: 25.81,
      description: "Assay of total testosterone",
    },
    {
      code: 84410,
      cost: 51.28,
      description: "Testosterone bioavailable",
    },
    {
      code: 84425,
      cost: 21.23,
      description: "Assay of vitamin b-1",
    },
    {
      code: 84430,
      cost: 11.63,
      description: "Assay of thiocyanate",
    },
    {
      code: 84431,
      cost: 35.11,
      description: "Thromboxane urine",
    },
    {
      code: 84432,
      cost: 16.06,
      description: "Assay of thyroglobulin",
    },
    {
      code: 84436,
      cost: 6.87,
      description: "Assay of total thyroxine",
    },
    {
      code: 84437,
      cost: 6.47,
      description: "Assay of neonatal thyroxine",
    },
    {
      code: 84439,
      cost: 9.02,
      description: "Assay of free thyroxine",
    },
    {
      code: 84442,
      cost: 14.78,
      description: "Assay of thyroid activity",
    },
    {
      code: 84443,
      cost: 16.8,
      description: "Assay thyroid stim hormone",
    },
    {
      code: 84443,
      cost: 16.8,
      description: "Assay thyroid stim hormone",
    },
    {
      code: 84445,
      cost: 50.86,
      description: "Assay of tsi globulin",
    },
    {
      code: 84446,
      cost: 14.18,
      description: "Assay of vitamin e",
    },
    {
      code: 84449,
      cost: 18,
      description: "Assay of transcortin",
    },
    {
      code: 84450,
      cost: 5.18,
      description: "Transferase (ast) (sgot)",
    },
    {
      code: 84450,
      cost: 5.18,
      description: "Transferase (ast) (sgot)",
    },
    {
      code: 84460,
      cost: 5.3,
      description: "Alanine amino (alt) (sgpt)",
    },
    {
      code: 84460,
      cost: 5.3,
      description: "Alanine amino (alt) (sgpt)",
    },
    {
      code: 84466,
      cost: 12.76,
      description: "Assay of transferrin",
    },
    {
      code: 84478,
      cost: 5.74,
      description: "Assay of triglycerides",
    },
    {
      code: 84478,
      cost: 5.74,
      description: "Assay of triglycerides",
    },
    {
      code: 84479,
      cost: 6.47,
      description: "Assay of thyroid (t3 or t4)",
    },
    {
      code: 84480,
      cost: 14.18,
      description: "Assay triiodothyronine (t3)",
    },
    {
      code: 84481,
      cost: 16.94,
      description: "Free assay (ft-3)",
    },
    {
      code: 84482,
      cost: 15.76,
      description: "T3 reverse",
    },
    {
      code: 84484,
      cost: 12.47,
      description: "Assay of troponin quant",
    },
    {
      code: 84485,
      cost: 7.2,
      description: "Assay duodenal fluid trypsin",
    },
    {
      code: 84488,
      cost: 7.3,
      description: "Test feces for trypsin",
    },
    {
      code: 84490,
      cost: 9.93,
      description: "Assay of feces for trypsin",
    },
    {
      code: 84510,
      cost: 10.63,
      description: "Assay of tyrosine",
    },
    {
      code: 84512,
      cost: 10.09,
      description: "Assay of troponin qual",
    },
    {
      code: 84520,
      cost: 3.95,
      description: "Assay of urea nitrogen",
    },
    {
      code: 84520,
      cost: 3.95,
      description: "Assay of urea nitrogen",
    },
    {
      code: 84525,
      cost: 5.13,
      description: "Urea nitrogen semi-quant",
    },
    {
      code: 84540,
      cost: 5.56,
      description: "Assay of urine/urea-n",
    },
    {
      code: 84545,
      cost: 7.2,
      description: "Urea-n clearance test",
    },
    {
      code: 84550,
      cost: 4.52,
      description: "Assay of blood/uric acid",
    },
    {
      code: 84550,
      cost: 4.52,
      description: "Assay of blood/uric acid",
    },
    {
      code: 84560,
      cost: 5.08,
      description: "Assay of urine/uric acid",
    },
    {
      code: 84577,
      cost: 16.8,
      description: "Assay of feces/urobilinogen",
    },
    {
      code: 84578,
      cost: 4.47,
      description: "Test urine urobilinogen",
    },
    {
      code: 84580,
      cost: 9.55,
      description: "Assay of urine urobilinogen",
    },
    {
      code: 84583,
      cost: 6.05,
      description: "Assay of urine urobilinogen",
    },
    {
      code: 84585,
      cost: 15.5,
      description: "Assay of urine vma",
    },
    {
      code: 84586,
      cost: 35.33,
      description: "Assay of vip",
    },
    {
      code: 84588,
      cost: 33.94,
      description: "Assay of vasopressin",
    },
    {
      code: 84590,
      cost: 11.61,
      description: "Assay of vitamin a",
    },
    {
      code: 84591,
      cost: 17.06,
      description: "Assay of nos vitamin",
    },
    {
      code: 84597,
      cost: 13.72,
      description: "Assay of vitamin k",
    },
    {
      code: 84600,
      cost: 17.11,
      description: "Assay of volatiles",
    },
    {
      code: 84620,
      cost: 12.91,
      description: "Xylose tolerance test",
    },
    {
      code: 84630,
      cost: 11.39,
      description: "Assay of zinc",
    },
    {
      code: 84681,
      cost: 20.81,
      description: "Assay of c-peptide",
    },
    {
      code: 84702,
      cost: 15.05,
      description: "Chorionic gonadotropin test",
    },
    {
      code: 84703,
      cost: 7.52,
      description: "Chorionic gonadotropin assay",
    },
    {
      code: 84703,
      cost: 7.52,
      description: "Chorionic gonadotropin assay",
    },
    {
      code: 84704,
      cost: 15.29,
      description: "Hcg free betachain test",
    },
    {
      code: 84830,
      cost: 12.7,
      description: "Ovulation tests",
    },
    {
      code: 85002,
      cost: 4.82,
      description: "Bleeding time test",
    },
    {
      code: 85004,
      cost: 6.47,
      description: "Automated diff wbc count",
    },
    {
      code: 85007,
      cost: 3.8,
      description: "Bl smear w/diff wbc count",
    },
    {
      code: 85008,
      cost: 3.43,
      description: "Bl smear w/o diff wbc count",
    },
    {
      code: 85009,
      cost: 5.07,
      description: "Manual diff wbc count b-coat",
    },
    {
      code: 85013,
      cost: 7,
      description: "Spun microhematocrit",
    },
    {
      code: 85014,
      cost: 2.37,
      description: "Hematocrit",
    },
    {
      code: 85014,
      cost: 2.37,
      description: "Hematocrit",
    },
    {
      code: 85018,
      cost: 2.37,
      description: "Hemoglobin",
    },
    {
      code: 85018,
      cost: 2.37,
      description: "Hemoglobin",
    },
    {
      code: 85025,
      cost: 7.77,
      description: "Complete cbc w/auto diff wbc",
    },
    {
      code: 85025,
      cost: 7.77,
      description: "Complete cbc w/auto diff wbc",
    },
    {
      code: 85027,
      cost: 6.47,
      description: "Complete cbc automated",
    },
    {
      code: 85032,
      cost: 4.31,
      description: "Manual cell count each",
    },
    {
      code: 85041,
      cost: 3.02,
      description: "Automated rbc count",
    },
    {
      code: 85044,
      cost: 4.31,
      description: "Manual reticulocyte count",
    },
    {
      code: 85045,
      cost: 3.99,
      description: "Automated reticulocyte count",
    },
    {
      code: 85046,
      cost: 5.57,
      description: "Reticyte/hgb concentrate",
    },
    {
      code: 85048,
      cost: 2.54,
      description: "Automated leukocyte count",
    },
    {
      code: 85049,
      cost: 4.48,
      description: "Automated platelet count",
    },
    {
      code: 85055,
      cost: 35.74,
      description: "Reticulated platelet assay",
    },
    {
      code: 85130,
      cost: 11.89,
      description: "Chromogenic substrate assay",
    },
    {
      code: 85170,
      cost: 16.3,
      description: "Blood clot retraction",
    },
    {
      code: 85175,
      cost: 20.37,
      description: "Blood clot lysis time",
    },
    {
      code: 85210,
      cost: 12.98,
      description: "Clot factor ii prothrom spec",
    },
    {
      code: 85220,
      cost: 17.65,
      description: "Blooc clot factor v test",
    },
    {
      code: 85230,
      cost: 17.9,
      description: "Clot factor vii proconvertin",
    },
    {
      code: 85240,
      cost: 17.9,
      description: "Clot factor viii ahg 1 stage",
    },
    {
      code: 85244,
      cost: 20.42,
      description: "Clot factor viii reltd antgn",
    },
    {
      code: 85245,
      cost: 22.94,
      description: "Clot factor viii vw ristoctn",
    },
    {
      code: 85246,
      cost: 22.94,
      description: "Clot factor viii vw antigen",
    },
    {
      code: 85247,
      cost: 22.94,
      description: "Clot factor viii multimetric",
    },
    {
      code: 85250,
      cost: 19.04,
      description: "Clot factor ix ptc/chrstmas",
    },
    {
      code: 85260,
      cost: 17.9,
      description: "Clot factor x stuart-power",
    },
    {
      code: 85270,
      cost: 17.9,
      description: "Clot factor xi pta",
    },
    {
      code: 85280,
      cost: 19.35,
      description: "Clot factor xii hageman",
    },
    {
      code: 85290,
      cost: 16.34,
      description: "Clot factor xiii fibrin stab",
    },
    {
      code: 85291,
      cost: 9.11,
      description: "Clot factor xiii fibrin scrn",
    },
    {
      code: 85292,
      cost: 18.93,
      description: "Clot factor fletcher fact",
    },
    {
      code: 85293,
      cost: 18.93,
      description: "Clot factor wght kininogen",
    },
    {
      code: 85300,
      cost: 11.85,
      description: "Antithrombin iii activity",
    },
    {
      code: 85301,
      cost: 10.81,
      description: "Antithrombin iii antigen",
    },
    {
      code: 85302,
      cost: 12.01,
      description: "Clot inhibit prot c antigen",
    },
    {
      code: 85303,
      cost: 13.84,
      description: "Clot inhibit prot c activity",
    },
    {
      code: 85305,
      cost: 11.61,
      description: "Clot inhibit prot s total",
    },
    {
      code: 85306,
      cost: 15.32,
      description: "Clot inhibit prot s free",
    },
    {
      code: 85307,
      cost: 15.32,
      description: "Assay activated protein c",
    },
    {
      code: 85335,
      cost: 12.87,
      description: "Factor inhibitor test",
    },
    {
      code: 85337,
      cost: 17.27,
      description: "Thrombomodulin",
    },
    {
      code: 85345,
      cost: 4.69,
      description: "Coagulation time lee & white",
    },
    {
      code: 85347,
      cost: 4.28,
      description: "Coagulation time activated",
    },
    {
      code: 85348,
      cost: 4.49,
      description: "Coagulation time otr method",
    },
    {
      code: 85360,
      cost: 8.41,
      description: "Euglobulin lysis",
    },
    {
      code: 85362,
      cost: 6.89,
      description: "Fibrin degradation products",
    },
    {
      code: 85366,
      cost: 80.46,
      description: "Fibrinogen test",
    },
    {
      code: 85370,
      cost: 12.43,
      description: "Fibrinogen test",
    },
    {
      code: 85378,
      cost: 9.72,
      description: "Fibrin degrade semiquant",
    },
    {
      code: 85379,
      cost: 10.18,
      description: "Fibrin degradation quant",
    },
    {
      code: 85380,
      cost: 10.18,
      description: "Fibrin degradj d-dimer",
    },
    {
      code: 85384,
      cost: 9.72,
      description: "Fibrinogen activity",
    },
    {
      code: 85385,
      cost: 14.46,
      description: "Fibrinogen antigen",
    },
    {
      code: 85390,
      cost: 15.48,
      description: "Fibrinolysins screen i&r",
    },
    {
      code: 85397,
      cost: 30.86,
      description: "Clotting funct activity",
    },
    {
      code: 85400,
      cost: 7.71,
      description: "Fibrinolytic plasmin",
    },
    {
      code: 85410,
      cost: 7.71,
      description: "Fibrinolytic antiplasmin",
    },
    {
      code: 85415,
      cost: 17.19,
      description: "Fibrinolytic plasminogen",
    },
    {
      code: 85420,
      cost: 6.53,
      description: "Fibrinolytic plasminogen",
    },
    {
      code: 85421,
      cost: 10.18,
      description: "Fibrinolytic plasminogen",
    },
    {
      code: 85441,
      cost: 4.2,
      description: "Heinz bodies direct",
    },
    {
      code: 85445,
      cost: 6.82,
      description: "Heinz bodies induced",
    },
    {
      code: 85460,
      cost: 7.73,
      description: "Hemoglobin fetal",
    },
    {
      code: 85461,
      cost: 9.36,
      description: "Hemoglobin fetal",
    },
    {
      code: 85475,
      cost: 8.87,
      description: "Hemolysin acid",
    },
    {
      code: 85520,
      cost: 13.09,
      description: "Heparin assay",
    },
    {
      code: 85525,
      cost: 11.84,
      description: "Heparin neutralization",
    },
    {
      code: 85530,
      cost: 13.09,
      description: "Heparin-protamine tolerance",
    },
    {
      code: 85536,
      cost: 6.88,
      description: "Iron stain peripheral blood",
    },
    {
      code: 85540,
      cost: 8.6,
      description: "Wbc alkaline phosphatase",
    },
    {
      code: 85547,
      cost: 8.6,
      description: "Rbc mechanical fragility",
    },
    {
      code: 85549,
      cost: 18.75,
      description: "Muramidase",
    },
    {
      code: 85555,
      cost: 7.47,
      description: "Rbc osmotic fragility",
    },
    {
      code: 85557,
      cost: 13.36,
      description: "Rbc osmotic fragility",
    },
    {
      code: 85576,
      cost: 24.91,
      description: "Blood platelet aggregation",
    },
    {
      code: 85576,
      cost: 24.91,
      description: "Blood platelet aggregation",
    },
    {
      code: 85597,
      cost: 17.98,
      description: "Phospholipid pltlt neutraliz",
    },
    {
      code: 85598,
      cost: 17.98,
      description: "Hexagnal phosph pltlt neutrl",
    },
    {
      code: 85610,
      cost: 4.29,
      description: "Prothrombin time",
    },
    {
      code: 85610,
      cost: 4.29,
      description: "Prothrombin time",
    },
    {
      code: 85611,
      cost: 3.94,
      description: "Prothrombin test",
    },
    {
      code: 85612,
      cost: 17.49,
      description: "Viper venom prothrombin time",
    },
    {
      code: 85613,
      cost: 9.58,
      description: "Russell viper venom diluted",
    },
    {
      code: 85635,
      cost: 9.85,
      description: "Reptilase test",
    },
    {
      code: 85651,
      cost: 4.27,
      description: "Rbc sed rate nonautomated",
    },
    {
      code: 85652,
      cost: 2.7,
      description: "Rbc sed rate automated",
    },
    {
      code: 85660,
      cost: 5.51,
      description: "Rbc sickle cell test",
    },
    {
      code: 85670,
      cost: 5.77,
      description: "Thrombin time plasma",
    },
    {
      code: 85675,
      cost: 6.85,
      description: "Thrombin time titer",
    },
    {
      code: 85705,
      cost: 9.63,
      description: "Thromboplastin inhibition",
    },
    {
      code: 85730,
      cost: 6.01,
      description: "Thromboplastin time partial",
    },
    {
      code: 85732,
      cost: 6.47,
      description: "Thromboplastin time partial",
    },
    {
      code: 85810,
      cost: 11.67,
      description: "Blood viscosity examination",
    },
    {
      code: 86000,
      cost: 6.98,
      description: "Agglutinins febrile antigen",
    },
    {
      code: 86001,
      cost: 7.82,
      description: "Allergen specific igg",
    },
    {
      code: 86003,
      cost: 5.22,
      description: "Allg spec ige crude xtrc ea",
    },
    {
      code: 86005,
      cost: 7.97,
      description: "Allg spec ige multiallg scr",
    },
    {
      code: 86008,
      cost: 17.93,
      description: "Allg spec ige recomb ea",
    },
    {
      code: 86021,
      cost: 15.05,
      description: "Wbc antibody identification",
    },
    {
      code: 86022,
      cost: 18.37,
      description: "Platelet antibodies",
    },
    {
      code: 86023,
      cost: 12.46,
      description: "Immunoglobulin assay",
    },
    {
      code: 86038,
      cost: 12.09,
      description: "Antinuclear antibodies",
    },
    {
      code: 86039,
      cost: 11.16,
      description: "Antinuclear antibodies (ana)",
    },
    {
      code: 86060,
      cost: 7.3,
      description: "Antistreptolysin o titer",
    },
    {
      code: 86063,
      cost: 5.77,
      description: "Antistreptolysin o screen",
    },
    {
      code: 86140,
      cost: 5.18,
      description: "C-reactive protein",
    },
    {
      code: 86141,
      cost: 12.95,
      description: "C-reactive protein hs",
    },
    {
      code: 86146,
      cost: 25.45,
      description: "Beta-2 glycoprotein antibody",
    },
    {
      code: 86147,
      cost: 25.45,
      description: "Cardiolipin antibody ea ig",
    },
    {
      code: 86148,
      cost: 16.07,
      description: "Anti-phospholipid antibody",
    },
    {
      code: 86152,
      cost: 250.78,
      description: "Cell enumeration & id",
    },
    {
      code: 86155,
      cost: 15.99,
      description: "Chemotaxis assay",
    },
    {
      code: 86156,
      cost: 8.07,
      description: "Cold agglutinin screen",
    },
    {
      code: 86157,
      cost: 8.06,
      description: "Cold agglutinin titer",
    },
    {
      code: 86160,
      cost: 12,
      description: "Complement antigen",
    },
    {
      code: 86161,
      cost: 12,
      description: "Complement/function activity",
    },
    {
      code: 86162,
      cost: 20.32,
      description: "Complement total (ch50)",
    },
    {
      code: 86171,
      cost: 10.01,
      description: "Complement fixation each",
    },
    {
      code: 86200,
      cost: 12.95,
      description: "Ccp antibody",
    },
    {
      code: 86215,
      cost: 13.25,
      description: "Deoxyribonuclease antibody",
    },
    {
      code: 86225,
      cost: 13.74,
      description: "Dna antibody native",
    },
    {
      code: 86226,
      cost: 12.11,
      description: "Dna antibody single strand",
    },
    {
      code: 86235,
      cost: 17.93,
      description: "Nuclear antigen antibody",
    },
    {
      code: 86255,
      cost: 12.05,
      description: "Fluorescent antibody screen",
    },
    {
      code: 86256,
      cost: 12.05,
      description: "Fluorescent antibody titer",
    },
    {
      code: 86277,
      cost: 15.74,
      description: "Growth hormone antibody",
    },
    {
      code: 86280,
      cost: 8.19,
      description: "Hemagglutination inhibition",
    },
    {
      code: 86294,
      cost: 25.57,
      description: "Immunoassay tumor qual",
    },
    {
      code: 86294,
      cost: 25.57,
      description: "Immunoassay tumor qual",
    },
    {
      code: 86300,
      cost: 20.81,
      description: "Immunoassay tumor ca 15-3",
    },
    {
      code: 86301,
      cost: 20.81,
      description: "Immunoassay tumor ca 19-9",
    },
    {
      code: 86304,
      cost: 20.81,
      description: "Immunoassay tumor ca 125",
    },
    {
      code: 86305,
      cost: 20.81,
      description: "Human epididymis protein 4",
    },
    {
      code: 86308,
      cost: 5.18,
      description: "Heterophile antibody screen",
    },
    {
      code: 86308,
      cost: 5.18,
      description: "Heterophile antibody screen",
    },
    {
      code: 86309,
      cost: 6.47,
      description: "Heterophile antibody titer",
    },
    {
      code: 86310,
      cost: 7.37,
      description: "Heterophile antibody absrbj",
    },
    {
      code: 86316,
      cost: 20.81,
      description: "Immunoassay tumor other",
    },
    {
      code: 86317,
      cost: 14.99,
      description: "Immunoassay infectious agent",
    },
    {
      code: 86318,
      cost: 18.09,
      description: "Immunoassay infectious agent",
    },
    {
      code: 86318,
      cost: 18.09,
      description: "Immunoassay infectious agent",
    },
    {
      code: 86320,
      cost: 29.92,
      description: "Serum immunoelectrophoresis",
    },
    {
      code: 86325,
      cost: 23.13,
      description: "Other immunoelectrophoresis",
    },
    {
      code: 86327,
      cost: 29.92,
      description: "Immunoelectrophoresis assay",
    },
    {
      code: 86329,
      cost: 14.05,
      description: "Immunodiffusion nes",
    },
    {
      code: 86331,
      cost: 11.98,
      description: "Immunodiffusion ouchterlony",
    },
    {
      code: 86332,
      cost: 24.37,
      description: "Immune complex assay",
    },
    {
      code: 86334,
      cost: 22.34,
      description: "Immunofix e-phoresis serum",
    },
    {
      code: 86335,
      cost: 29.35,
      description: "Immunfix e-phorsis/urine/csf",
    },
    {
      code: 86336,
      cost: 15.59,
      description: "Inhibin a",
    },
    {
      code: 86337,
      cost: 21.41,
      description: "Insulin antibodies",
    },
    {
      code: 86340,
      cost: 15.08,
      description: "Intrinsic factor antibody",
    },
    {
      code: 86341,
      cost: 23.57,
      description: "Islet cell antibody",
    },
    {
      code: 86343,
      cost: 12.46,
      description: "Leukocyte histamine release",
    },
    {
      code: 86344,
      cost: 10.39,
      description: "Leukocyte phagocytosis",
    },
    {
      code: 86352,
      cost: 135.86,
      description: "Cell function assay w/stim",
    },
    {
      code: 86353,
      cost: 49.03,
      description: "Lymphocyte transformation",
    },
    {
      code: 86355,
      cost: 37.73,
      description: "B cells total count",
    },
    {
      code: 86356,
      cost: 26.78,
      description: "Mononuclear cell antigen",
    },
    {
      code: 86357,
      cost: 37.73,
      description: "Nk cells total count",
    },
    {
      code: 86359,
      cost: 37.73,
      description: "T cells total count",
    },
    {
      code: 86360,
      cost: 46.98,
      description: "T cell absolute count/ratio",
    },
    {
      code: 86361,
      cost: 26.78,
      description: "T cell absolute count",
    },
    {
      code: 86367,
      cost: 77.78,
      description: "Stem cells total count",
    },
    {
      code: 86376,
      cost: 14.55,
      description: "Microsomal antibody each",
    },
    {
      code: 86382,
      cost: 16.91,
      description: "Neutralization test viral",
    },
    {
      code: 86384,
      cost: 13.61,
      description: "Nitroblue tetrazolium dye",
    },
    {
      code: 86386,
      cost: 21.78,
      description: "Nuclear matrix protein 22",
    },
    {
      code: 86386,
      cost: 21.78,
      description: "Nuclear matrix protein 22",
    },
    {
      code: 86403,
      cost: 11.54,
      description: "Particle agglut antbdy scrn",
    },
    {
      code: 86406,
      cost: 10.64,
      description: "Particle agglut antbdy titr",
    },
    {
      code: 86430,
      cost: 6.14,
      description: "Rheumatoid factor test qual",
    },
    {
      code: 86431,
      cost: 5.67,
      description: "Rheumatoid factor quant",
    },
    {
      code: 86480,
      cost: 61.98,
      description: "Tb test cell immun measure",
    },
    {
      code: 86481,
      cost: 100,
      description: "Tb ag response t-cell susp",
    },
    {
      code: 86590,
      cost: 12.66,
      description: "Streptokinase antibody",
    },
    {
      code: 86592,
      cost: 4.27,
      description: "Syphilis test non-trep qual",
    },
    {
      code: 86593,
      cost: 4.4,
      description: "Syphilis test non-trep quant",
    },
    {
      code: 86602,
      cost: 10.18,
      description: "Antinomyces antibody",
    },
    {
      code: 86603,
      cost: 12.87,
      description: "Adenovirus antibody",
    },
    {
      code: 86606,
      cost: 15.05,
      description: "Aspergillus antibody",
    },
    {
      code: 86609,
      cost: 12.88,
      description: "Bacterium antibody",
    },
    {
      code: 86611,
      cost: 10.18,
      description: "Bartonella antibody",
    },
    {
      code: 86612,
      cost: 12.9,
      description: "Blastomyces antibody",
    },
    {
      code: 86615,
      cost: 13.19,
      description: "Bordetella antibody",
    },
    {
      code: 86617,
      cost: 15.49,
      description: "Lyme disease antibody",
    },
    {
      code: 86618,
      cost: 17.03,
      description: "Lyme disease antibody",
    },
    {
      code: 86618,
      cost: 17.03,
      description: "Lyme disease antibody",
    },
    {
      code: 86619,
      cost: 13.38,
      description: "Borrelia antibody",
    },
    {
      code: 86622,
      cost: 8.93,
      description: "Brucella antibody",
    },
    {
      code: 86625,
      cost: 13.12,
      description: "Campylobacter antibody",
    },
    {
      code: 86628,
      cost: 12.01,
      description: "Candida antibody",
    },
    {
      code: 86631,
      cost: 11.82,
      description: "Chlamydia antibody",
    },
    {
      code: 86632,
      cost: 12.68,
      description: "Chlamydia igm antibody",
    },
    {
      code: 86635,
      cost: 11.47,
      description: "Coccidioides antibody",
    },
    {
      code: 86638,
      cost: 12.12,
      description: "Q fever antibody",
    },
    {
      code: 86641,
      cost: 14.41,
      description: "Cryptococcus antibody",
    },
    {
      code: 86644,
      cost: 14.39,
      description: "Cmv antibody",
    },
    {
      code: 86645,
      cost: 16.85,
      description: "Cmv antibody igm",
    },
    {
      code: 86648,
      cost: 15.21,
      description: "Diphtheria antibody",
    },
    {
      code: 86651,
      cost: 13.19,
      description: "Encephalitis californ antbdy",
    },
    {
      code: 86652,
      cost: 13.19,
      description: "Encephaltis east eqne anbdy",
    },
    {
      code: 86653,
      cost: 13.19,
      description: "Encephaltis st louis antbody",
    },
    {
      code: 86654,
      cost: 13.19,
      description: "Encephaltis west eqne antbdy",
    },
    {
      code: 86658,
      cost: 13.03,
      description: "Enterovirus antibody",
    },
    {
      code: 86663,
      cost: 13.12,
      description: "Epstein-barr antibody",
    },
    {
      code: 86664,
      cost: 15.29,
      description: "Epstein-barr nuclear antigen",
    },
    {
      code: 86665,
      cost: 18.14,
      description: "Epstein-barr capsid vca",
    },
    {
      code: 86666,
      cost: 10.18,
      description: "Ehrlichia antibody",
    },
    {
      code: 86668,
      cost: 14.16,
      description: "Francisella tularensis",
    },
    {
      code: 86671,
      cost: 12.25,
      description: "Fungus nes antibody",
    },
    {
      code: 86674,
      cost: 14.72,
      description: "Giardia lamblia antibody",
    },
    {
      code: 86677,
      cost: 16.85,
      description: "Helicobacter pylori antibody",
    },
    {
      code: 86682,
      cost: 13.01,
      description: "Helminth antibody",
    },
    {
      code: 86684,
      cost: 15.84,
      description: "Hemophilus influenza antibdy",
    },
    {
      code: 86687,
      cost: 9.09,
      description: "Htlv-i antibody",
    },
    {
      code: 86688,
      cost: 14,
      description: "Htlv-ii antibody",
    },
    {
      code: 86689,
      cost: 19.35,
      description: "Htlv/hiv confirmj antibody",
    },
    {
      code: 86692,
      cost: 17.16,
      description: "Hepatitis delta agent antbdy",
    },
    {
      code: 86694,
      cost: 14.39,
      description: "Herpes simplex nes antbdy",
    },
    {
      code: 86695,
      cost: 13.19,
      description: "Herpes simplex type 1 test",
    },
    {
      code: 86696,
      cost: 19.35,
      description: "Herpes simplex type 2 test",
    },
    {
      code: 86698,
      cost: 13.79,
      description: "Histoplasma antibody",
    },
    {
      code: 86701,
      cost: 8.89,
      description: "Hiv-1antibody",
    },
    {
      code: 86701,
      cost: 8.89,
      description: "Hiv-1antibody",
    },
    {
      code: 86702,
      cost: 13.52,
      description: "Hiv-2 antibody",
    },
    {
      code: 86703,
      cost: 13.71,
      description: "Hiv-1/hiv-2 1 result antbdy",
    },
    {
      code: 86704,
      cost: 12.05,
      description: "Hep b core antibody total",
    },
    {
      code: 86705,
      cost: 11.77,
      description: "Hep b core antibody igm",
    },
    {
      code: 86706,
      cost: 10.74,
      description: "Hep b surface antibody",
    },
    {
      code: 86707,
      cost: 11.57,
      description: "Hepatitis be antibody",
    },
    {
      code: 86708,
      cost: 12.39,
      description: "Hepatitis a antibody",
    },
    {
      code: 86709,
      cost: 11.26,
      description: "Hepatitis a igm antibody",
    },
    {
      code: 86710,
      cost: 13.55,
      description: "Influenza virus antibody",
    },
    {
      code: 86711,
      cost: 16.89,
      description: "John cunningham antibody",
    },
    {
      code: 86713,
      cost: 15.3,
      description: "Legionella antibody",
    },
    {
      code: 86717,
      cost: 12.25,
      description: "Leishmania antibody",
    },
    {
      code: 86720,
      cost: 16.2,
      description: "Leptospira antibody",
    },
    {
      code: 86723,
      cost: 13.19,
      description: "Listeria monocytogenes",
    },
    {
      code: 86727,
      cost: 12.87,
      description: "Lymph choriomeningitis ab",
    },
    {
      code: 86732,
      cost: 15,
      description: "Mucormycosis antibody",
    },
    {
      code: 86735,
      cost: 13.05,
      description: "Mumps antibody",
    },
    {
      code: 86738,
      cost: 13.24,
      description: "Mycoplasma antibody",
    },
    {
      code: 86741,
      cost: 13.19,
      description: "Neisseria meningitidis",
    },
    {
      code: 86744,
      cost: 15.99,
      description: "Nocardia antibody",
    },
    {
      code: 86747,
      cost: 15.03,
      description: "Parvovirus antibody",
    },
    {
      code: 86750,
      cost: 13.19,
      description: "Malaria antibody",
    },
    {
      code: 86753,
      cost: 12.39,
      description: "Protozoa antibody nos",
    },
    {
      code: 86756,
      cost: 15.89,
      description: "Respiratory virus antibody",
    },
    {
      code: 86757,
      cost: 19.35,
      description: "Rickettsia antibody",
    },
    {
      code: 86759,
      cost: 18.23,
      description: "Rotavirus antibody",
    },
    {
      code: 86762,
      cost: 14.39,
      description: "Rubella antibody",
    },
    {
      code: 86765,
      cost: 12.88,
      description: "Rubeola antibody",
    },
    {
      code: 86768,
      cost: 13.19,
      description: "Salmonella antibody",
    },
    {
      code: 86771,
      cost: 24.48,
      description: "Shigella antibody",
    },
    {
      code: 86774,
      cost: 14.8,
      description: "Tetanus antibody",
    },
    {
      code: 86777,
      cost: 14.39,
      description: "Toxoplasma antibody",
    },
    {
      code: 86778,
      cost: 14.41,
      description: "Toxoplasma antibody igm",
    },
    {
      code: 86780,
      cost: 13.24,
      description: "Treponema pallidum",
    },
    {
      code: 86780,
      cost: 13.24,
      description: "Treponema pallidum",
    },
    {
      code: 86784,
      cost: 12.56,
      description: "Trichinella antibody",
    },
    {
      code: 86787,
      cost: 12.88,
      description: "Varicella-zoster antibody",
    },
    {
      code: 86788,
      cost: 16.85,
      description: "West nile virus ab igm",
    },
    {
      code: 86789,
      cost: 14.39,
      description: "West nile virus antibody",
    },
    {
      code: 86790,
      cost: 12.88,
      description: "Virus antibody nos",
    },
    {
      code: 86793,
      cost: 13.19,
      description: "Yersinia antibody",
    },
    {
      code: 86794,
      cost: 16.85,
      description: "Zika virus igm antibody",
    },
    {
      code: 86800,
      cost: 15.91,
      description: "Thyroglobulin antibody",
    },
    {
      code: 86803,
      cost: 14.27,
      description: "Hepatitis c ab test",
    },
    {
      code: 86803,
      cost: 14.27,
      description: "Hepatitis c ab test",
    },
    {
      code: 86804,
      cost: 15.49,
      description: "Hep c ab test confirm",
    },
    {
      code: 86805,
      cost: 189.51,
      description: "Lymphocytotoxicity assay",
    },
    {
      code: 86806,
      cost: 47.59,
      description: "Lymphocytotoxicity assay",
    },
    {
      code: 86807,
      cost: 78.65,
      description: "Cytotoxic antibody screening",
    },
    {
      code: 86808,
      cost: 29.68,
      description: "Cytotoxic antibody screening",
    },
    {
      code: 86812,
      cost: 25.81,
      description: "Hla typing a b or c",
    },
    {
      code: 86813,
      cost: 58,
      description: "Hla typing a b or c",
    },
    {
      code: 86816,
      cost: 30.17,
      description: "Hla typing dr/dq",
    },
    {
      code: 86817,
      cost: 106.14,
      description: "Hla typing dr/dq",
    },
    {
      code: 86821,
      cost: 36.56,
      description: "Lymphocyte culture mixed",
    },
    {
      code: 86825,
      cost: 109.49,
      description: "Hla x-math non-cytotoxic",
    },
    {
      code: 86826,
      cost: 36.53,
      description: "Hla x-match noncytotoxc addl",
    },
    {
      code: 86828,
      cost: 64.19,
      description: "Hla class i&ii antibody qual",
    },
    {
      code: 86829,
      cost: 64.19,
      description: "Hla class i/ii antibody qual",
    },
    {
      code: 86830,
      cost: 95.52,
      description: "Hla class i phenotype qual",
    },
    {
      code: 86831,
      cost: 81.88,
      description: "Hla class ii phenotype qual",
    },
    {
      code: 86832,
      cost: 323.75,
      description: "Hla class i high defin qual",
    },
    {
      code: 86833,
      cost: 325.8,
      description: "Hla class ii high defin qual",
    },
    {
      code: 86834,
      cost: 357.56,
      description: "Hla class i semiquant panel",
    },
    {
      code: 86835,
      cost: 322.96,
      description: "Hla class ii semiquant panel",
    },
    {
      code: 86850,
      cost: 9.77,
      description: "Rbc antibody screen",
    },
    {
      code: 86880,
      cost: 5.39,
      description: "Coombs test direct",
    },
    {
      code: 86885,
      cost: 5.72,
      description: "Coombs test indirect qual",
    },
    {
      code: 86886,
      cost: 5.18,
      description: "Coombs test indirect titer",
    },
    {
      code: 86900,
      cost: 2.99,
      description: "Blood typing serologic abo",
    },
    {
      code: 86901,
      cost: 2.99,
      description: "Blood typing serologic rh(d)",
    },
    {
      code: 86902,
      cost: 6.35,
      description: "Blood type antigen donor ea",
    },
    {
      code: 86904,
      cost: 16.34,
      description: "Blood typing patient serum",
    },
    {
      code: 86905,
      cost: 3.83,
      description: "Blood typing rbc antigens",
    },
    {
      code: 86906,
      cost: 7.75,
      description: "Bld typing serologic rh phnt",
    },
    {
      code: 86940,
      cost: 8.77,
      description: "Hemolysins/agglutinins auto",
    },
    {
      code: 86941,
      cost: 12.11,
      description: "Hemolysins/agglutinins",
    },
    {
      code: 87003,
      cost: 16.84,
      description: "Small animal inoculation",
    },
    {
      code: 87015,
      cost: 6.68,
      description: "Specimen infect agnt concntj",
    },
    {
      code: 87040,
      cost: 10.32,
      description: "Blood culture for bacteria",
    },
    {
      code: 87045,
      cost: 9.44,
      description: "Feces culture aerobic bact",
    },
    {
      code: 87046,
      cost: 9.44,
      description: "Stool cultr aerobic bact ea",
    },
    {
      code: 87070,
      cost: 8.62,
      description: "Culture othr specimn aerobic",
    },
    {
      code: 87071,
      cost: 9.89,
      description: "Culture aerobic quant other",
    },
    {
      code: 87073,
      cost: 9.66,
      description: "Culture bacteria anaerobic",
    },
    {
      code: 87075,
      cost: 9.47,
      description: "Cultr bacteria except blood",
    },
    {
      code: 87076,
      cost: 8.08,
      description: "Culture anaerobe ident each",
    },
    {
      code: 87077,
      cost: 8.08,
      description: "Culture aerobic identify",
    },
    {
      code: 87077,
      cost: 8.08,
      description: "Culture aerobic identify",
    },
    {
      code: 87081,
      cost: 6.63,
      description: "Culture screen only",
    },
    {
      code: 87084,
      cost: 27.07,
      description: "Culture of specimen by kit",
    },
    {
      code: 87086,
      cost: 8.07,
      description: "Urine culture/colony count",
    },
    {
      code: 87088,
      cost: 8.09,
      description: "Urine bacteria culture",
    },
    {
      code: 87101,
      cost: 7.71,
      description: "Skin fungi culture",
    },
    {
      code: 87102,
      cost: 8.41,
      description: "Fungus isolation culture",
    },
    {
      code: 87103,
      cost: 20.46,
      description: "Blood fungus culture",
    },
    {
      code: 87106,
      cost: 10.32,
      description: "Fungi identification yeast",
    },
    {
      code: 87107,
      cost: 10.32,
      description: "Fungi identification mold",
    },
    {
      code: 87109,
      cost: 15.39,
      description: "Mycoplasma",
    },
    {
      code: 87110,
      cost: 19.6,
      description: "Chlamydia culture",
    },
    {
      code: 87116,
      cost: 10.8,
      description: "Mycobacteria culture",
    },
    {
      code: 87118,
      cost: 14.61,
      description: "Mycobacteric identification",
    },
    {
      code: 87140,
      cost: 5.57,
      description: "Culture type immunofluoresc",
    },
    {
      code: 87143,
      cost: 12.52,
      description: "Culture typing glc/hplc",
    },
    {
      code: 87147,
      cost: 5.18,
      description: "Culture type immunologic",
    },
    {
      code: 87149,
      cost: 20.05,
      description: "Dna/rna direct probe",
    },
    {
      code: 87150,
      cost: 35.09,
      description: "Dna/rna amplified probe",
    },
    {
      code: 87152,
      cost: 7.74,
      description: "Culture type pulse field gel",
    },
    {
      code: 87153,
      cost: 115.36,
      description: "Dna/rna sequencing",
    },
    {
      code: 87158,
      cost: 7.74,
      description: "Culture typing added method",
    },
    {
      code: 87164,
      cost: 10.74,
      description: "Dark field examination",
    },
    {
      code: 87166,
      cost: 11.3,
      description: "Dark field examination",
    },
    {
      code: 87168,
      cost: 4.27,
      description: "Macroscopic exam arthropod",
    },
    {
      code: 87169,
      cost: 4.31,
      description: "Macroscopic exam parasite",
    },
    {
      code: 87172,
      cost: 4.27,
      description: "Pinworm exam",
    },
    {
      code: 87176,
      cost: 5.88,
      description: "Tissue homogenization cultr",
    },
    {
      code: 87177,
      cost: 8.9,
      description: "Ova and parasites smears",
    },
    {
      code: 87181,
      cost: 4.75,
      description: "Microbe susceptible diffuse",
    },
    {
      code: 87184,
      cost: 7.48,
      description: "Microbe susceptible disk",
    },
    {
      code: 87185,
      cost: 4.75,
      description: "Microbe susceptible enzyme",
    },
    {
      code: 87186,
      cost: 8.65,
      description: "Microbe susceptible mic",
    },
    {
      code: 87187,
      cost: 40.17,
      description: "Microbe susceptible mlc",
    },
    {
      code: 87188,
      cost: 6.64,
      description: "Microbe suscept macrobroth",
    },
    {
      code: 87190,
      cost: 7.31,
      description: "Microbe suscept mycobacteri",
    },
    {
      code: 87197,
      cost: 15.02,
      description: "Bactericidal level serum",
    },
    {
      code: 87205,
      cost: 4.27,
      description: "Smear gram stain",
    },
    {
      code: 87206,
      cost: 5.39,
      description: "Smear fluorescent/acid stai",
    },
    {
      code: 87207,
      cost: 5.99,
      description: "Smear special stain",
    },
    {
      code: 87209,
      cost: 17.98,
      description: "Smear complex stain",
    },
    {
      code: 87210,
      cost: 5.82,
      description: "Smear wet mount saline/ink",
    },
    {
      code: 87210,
      cost: 5.82,
      description: "Smear wet mount saline/ink",
    },
    {
      code: 87220,
      cost: 4.27,
      description: "Tissue exam for fungi",
    },
    {
      code: 87230,
      cost: 19.74,
      description: "Assay toxin or antitoxin",
    },
    {
      code: 87250,
      cost: 19.56,
      description: "Virus inoculate eggs/animal",
    },
    {
      code: 87252,
      cost: 26.07,
      description: "Virus inoculation tissue",
    },
    {
      code: 87253,
      cost: 20.2,
      description: "Virus inoculate tissue addl",
    },
    {
      code: 87254,
      cost: 19.56,
      description: "Virus inoculation shell via",
    },
    {
      code: 87255,
      cost: 33.86,
      description: "Genet virus isolate hsv",
    },
    {
      code: 87260,
      cost: 14.43,
      description: "Adenovirus ag if",
    },
    {
      code: 87265,
      cost: 11.98,
      description: "Pertussis ag if",
    },
    {
      code: 87267,
      cost: 13.42,
      description: "Enterovirus antibody dfa",
    },
    {
      code: 87269,
      cost: 13.61,
      description: "Giardia ag if",
    },
    {
      code: 87270,
      cost: 11.98,
      description: "Chlamydia trachomatis ag if",
    },
    {
      code: 87271,
      cost: 13.42,
      description: "Cytomegalovirus dfa",
    },
    {
      code: 87272,
      cost: 11.98,
      description: "Cryptosporidium ag if",
    },
    {
      code: 87273,
      cost: 11.98,
      description: "Herpes simplex 2 ag if",
    },
    {
      code: 87274,
      cost: 11.98,
      description: "Herpes simplex 1 ag if",
    },
    {
      code: 87275,
      cost: 12.25,
      description: "Influenza b ag if",
    },
    {
      code: 87276,
      cost: 16.07,
      description: "Influenza a ag if",
    },
    {
      code: 87278,
      cost: 15.6,
      description: "Legion pneumophilia ag if",
    },
    {
      code: 87279,
      cost: 16.43,
      description: "Parainfluenza ag if",
    },
    {
      code: 87280,
      cost: 13.42,
      description: "Respiratory syncytial ag if",
    },
    {
      code: 87281,
      cost: 11.98,
      description: "Pneumocystis carinii ag if",
    },
    {
      code: 87283,
      cost: 60.8,
      description: "Rubeola ag if",
    },
    {
      code: 87285,
      cost: 12.18,
      description: "Treponema pallidum ag if",
    },
    {
      code: 87290,
      cost: 13.42,
      description: "Varicella zoster ag if",
    },
    {
      code: 87299,
      cost: 16.1,
      description: "Antibody detection nos if",
    },
    {
      code: 87300,
      cost: 11.98,
      description: "Ag detection polyval if",
    },
    {
      code: 87301,
      cost: 11.98,
      description: "Adenovirus ag ia",
    },
    {
      code: 87305,
      cost: 11.98,
      description: "Aspergillus ag ia",
    },
    {
      code: 87320,
      cost: 15,
      description: "Chylmd trach ag ia",
    },
    {
      code: 87324,
      cost: 11.98,
      description: "Clostridium ag ia",
    },
    {
      code: 87327,
      cost: 13.42,
      description: "Cryptococcus neoform ag ia",
    },
    {
      code: 87328,
      cost: 13.82,
      description: "Cryptosporidium ag ia",
    },
    {
      code: 87329,
      cost: 11.98,
      description: "Giardia ag ia",
    },
    {
      code: 87332,
      cost: 11.98,
      description: "Cytomegalovirus ag ia",
    },
    {
      code: 87335,
      cost: 12.66,
      description: "E coli 0157 ag ia",
    },
    {
      code: 87336,
      cost: 16,
      description: "Entamoeb hist dispr ag ia",
    },
    {
      code: 87337,
      cost: 11.98,
      description: "Entamoeb hist group ag ia",
    },
    {
      code: 87338,
      cost: 14.38,
      description: "Hpylori stool ia",
    },
    {
      code: 87338,
      cost: 14.38,
      description: "Hpylori stool ia",
    },
    {
      code: 87339,
      cost: 16,
      description: "H pylori ag ia",
    },
    {
      code: 87340,
      cost: 10.33,
      description: "Hepatitis b surface ag ia",
    },
    {
      code: 87341,
      cost: 10.33,
      description: "Hepatitis b surface ag ia",
    },
    {
      code: 87350,
      cost: 11.53,
      description: "Hepatitis be ag ia",
    },
    {
      code: 87380,
      cost: 18.36,
      description: "Hepatitis delta ag ia",
    },
    {
      code: 87385,
      cost: 13.25,
      description: "Histoplasma capsul ag ia",
    },
    {
      code: 87389,
      cost: 24.08,
      description: "Hiv-1 ag w/hiv-1 & hiv-2 ab",
    },
    {
      code: 87389,
      cost: 24.08,
      description: "Hiv-1 ag w/hiv-1 & hiv-2 ab",
    },
    {
      code: 87390,
      cost: 24.06,
      description: "Hiv-1 ag ia",
    },
    {
      code: 87391,
      cost: 21.9,
      description: "Hiv-2 ag ia",
    },
    {
      code: 87400,
      cost: 14.13,
      description: "Influenza a/b ag ia",
    },
    {
      code: 87420,
      cost: 13.91,
      description: "Resp syncytial ag ia",
    },
    {
      code: 87425,
      cost: 11.98,
      description: "Rotavirus ag ia",
    },
    {
      code: 87427,
      cost: 11.98,
      description: "Shiga-like toxin ag ia",
    },
    {
      code: 87430,
      cost: 16.81,
      description: "Strep a ag ia",
    },
    {
      code: 87449,
      cost: 11.98,
      description: "Ag detect nos ia mult",
    },
    {
      code: 87449,
      cost: 11.98,
      description: "Ag detect nos ia mult",
    },
    {
      code: 87450,
      cost: 9.59,
      description: "Ag detect nos ia single",
    },
    {
      code: 87451,
      cost: 10.51,
      description: "Ag detect polyval ia mult",
    },
    {
      code: 87471,
      cost: 35.09,
      description: "Bartonella dna amp probe",
    },
    {
      code: 87472,
      cost: 42.84,
      description: "Bartonella dna quant",
    },
    {
      code: 87475,
      cost: 20.05,
      description: "Lyme dis dna dir probe",
    },
    {
      code: 87476,
      cost: 35.09,
      description: "Lyme dis dna amp probe",
    },
    {
      code: 87480,
      cost: 20.05,
      description: "Candida dna dir probe",
    },
    {
      code: 87481,
      cost: 35.09,
      description: "Candida dna amp probe",
    },
    {
      code: 87482,
      cost: 55.74,
      description: "Candida dna quant",
    },
    {
      code: 87483,
      cost: 416.78,
      description: "Cns dna amp probe type 12-25",
    },
    {
      code: 87485,
      cost: 20.05,
      description: "Chylmd pneum dna dir probe",
    },
    {
      code: 87486,
      cost: 35.09,
      description: "Chylmd pneum dna amp probe",
    },
    {
      code: 87487,
      cost: 42.84,
      description: "Chylmd pneum dna quant",
    },
    {
      code: 87490,
      cost: 22.75,
      description: "Chylmd trach dna dir probe",
    },
    {
      code: 87491,
      cost: 35.09,
      description: "Chylmd trach dna amp probe",
    },
    {
      code: 87492,
      cost: 53.47,
      description: "Chylmd trach dna quant",
    },
    {
      code: 87493,
      cost: 37.27,
      description: "C diff amplified probe",
    },
    {
      code: 87495,
      cost: 30.03,
      description: "Cytomeg dna dir probe",
    },
    {
      code: 87496,
      cost: 35.09,
      description: "Cytomeg dna amp probe",
    },
    {
      code: 87497,
      cost: 42.84,
      description: "Cytomeg dna quant",
    },
    {
      code: 87498,
      cost: 35.09,
      description: "Enterovirus probe&revrs trns",
    },
    {
      code: 87500,
      cost: 35.09,
      description: "Vanomycin dna amp probe",
    },
    {
      code: 87501,
      cost: 51.31,
      description: "Influenza dna amp prob 1+",
    },
    {
      code: 87502,
      cost: 95.8,
      description: "Influenza dna amp probe",
    },
    {
      code: 87502,
      cost: 95.8,
      description: "Influenza dna amp probe",
    },
    {
      code: 87503,
      cost: 29.22,
      description: "Influenza dna amp prob addl",
    },
    {
      code: 87505,
      cost: 128.29,
      description: "Nfct agent detection gi",
    },
    {
      code: 87506,
      cost: 262.99,
      description: "Iadna-dna/rna probe tq 6-11",
    },
    {
      code: 87507,
      cost: 416.78,
      description: "Iadna-dna/rna probe tq 12-25",
    },
    {
      code: 87510,
      cost: 20.05,
      description: "Gardner vag dna dir probe",
    },
    {
      code: 87511,
      cost: 35.09,
      description: "Gardner vag dna amp probe",
    },
    {
      code: 87512,
      cost: 41.76,
      description: "Gardner vag dna quant",
    },
    {
      code: 87516,
      cost: 35.09,
      description: "Hepatitis b dna amp probe",
    },
    {
      code: 87517,
      cost: 42.84,
      description: "Hepatitis b dna quant",
    },
    {
      code: 87520,
      cost: 31.22,
      description: "Hepatitis c rna dir probe",
    },
    {
      code: 87521,
      cost: 35.09,
      description: "Hepatitis c probe&rvrs trnsc",
    },
    {
      code: 87522,
      cost: 42.84,
      description: "Hepatitis c revrs trnscrpj",
    },
    {
      code: 87525,
      cost: 29.8,
      description: "Hepatitis g dna dir probe",
    },
    {
      code: 87526,
      cost: 39.26,
      description: "Hepatitis g dna amp probe",
    },
    {
      code: 87527,
      cost: 41.76,
      description: "Hepatitis g dna quant",
    },
    {
      code: 87528,
      cost: 20.05,
      description: "Hsv dna dir probe",
    },
    {
      code: 87529,
      cost: 35.09,
      description: "Hsv dna amp probe",
    },
    {
      code: 87530,
      cost: 42.84,
      description: "Hsv dna quant",
    },
    {
      code: 87531,
      cost: 58,
      description: "Hhv-6 dna dir probe",
    },
    {
      code: 87532,
      cost: 35.09,
      description: "Hhv-6 dna amp probe",
    },
    {
      code: 87533,
      cost: 41.76,
      description: "Hhv-6 dna quant",
    },
    {
      code: 87534,
      cost: 21.92,
      description: "Hiv-1 dna dir probe",
    },
    {
      code: 87535,
      cost: 35.09,
      description: "Hiv-1 probe&reverse trnscrpj",
    },
    {
      code: 87536,
      cost: 85.1,
      description: "Hiv-1 quant&revrse trnscrpj",
    },
    {
      code: 87537,
      cost: 21.92,
      description: "Hiv-2 dna dir probe",
    },
    {
      code: 87538,
      cost: 35.09,
      description: "Hiv-2 probe&revrse trnscripj",
    },
    {
      code: 87539,
      cost: 58.62,
      description: "Hiv-2 quant&revrse trnscripj",
    },
    {
      code: 87540,
      cost: 20.05,
      description: "Legion pneumo dna dir prob",
    },
    {
      code: 87541,
      cost: 35.09,
      description: "Legion pneumo dna amp prob",
    },
    {
      code: 87542,
      cost: 41.76,
      description: "Legion pneumo dna quant",
    },
    {
      code: 87550,
      cost: 20.05,
      description: "Mycobacteria dna dir probe",
    },
    {
      code: 87551,
      cost: 48.24,
      description: "Mycobacteria dna amp probe",
    },
    {
      code: 87552,
      cost: 42.84,
      description: "Mycobacteria dna quant",
    },
    {
      code: 87555,
      cost: 26.88,
      description: "M.tuberculo dna dir probe",
    },
    {
      code: 87556,
      cost: 41.68,
      description: "M.tuberculo dna amp probe",
    },
    {
      code: 87557,
      cost: 42.84,
      description: "M.tuberculo dna quant",
    },
    {
      code: 87560,
      cost: 27.29,
      description: "M.avium-intra dna dir prob",
    },
    {
      code: 87561,
      cost: 35.09,
      description: "M.avium-intra dna amp prob",
    },
    {
      code: 87562,
      cost: 42.84,
      description: "M.avium-intra dna quant",
    },
    {
      code: 87563,
      cost: 35.09,
      description: "M. genitalium amp probe",
    },
    {
      code: 87580,
      cost: 20.05,
      description: "M.pneumon dna dir probe",
    },
    {
      code: 87581,
      cost: 35.09,
      description: "M.pneumon dna amp probe",
    },
    {
      code: 87582,
      cost: 302.62,
      description: "M.pneumon dna quant",
    },
    {
      code: 87590,
      cost: 26.88,
      description: "N.gonorrhoeae dna dir prob",
    },
    {
      code: 87591,
      cost: 35.09,
      description: "N.gonorrhoeae dna amp prob",
    },
    {
      code: 87592,
      cost: 42.84,
      description: "N.gonorrhoeae dna quant",
    },
    {
      code: 87623,
      cost: 35.09,
      description: "Hpv low-risk types",
    },
    {
      code: 87624,
      cost: 35.09,
      description: "Hpv high-risk types",
    },
    {
      code: 87625,
      cost: 40.55,
      description: "Hpv types 16 & 18 only",
    },
    {
      code: 87631,
      cost: 142.63,
      description: "Resp virus 3-5 targets",
    },
    {
      code: 87631,
      cost: 142.63,
      description: "Resp virus 3-5 targets",
    },
    {
      code: 87632,
      cost: 218.06,
      description: "Resp virus 6-11 targets",
    },
    {
      code: 87633,
      cost: 416.78,
      description: "Resp virus 12-25 targets",
    },
    {
      code: 87633,
      cost: 416.78,
      description: "Resp virus 12-25 targets",
    },
    {
      code: 87634,
      cost: 70.2,
      description: "Rsv dna/rna amp probe",
    },
    {
      code: 87634,
      cost: 70.2,
      description: "Rsv dna/rna amp probe",
    },
    {
      code: 87640,
      cost: 35.09,
      description: "Staph a dna amp probe",
    },
    {
      code: 87641,
      cost: 35.09,
      description: "Mr-staph dna amp probe",
    },
    {
      code: 87650,
      cost: 20.05,
      description: "Strep a dna dir probe",
    },
    {
      code: 87650,
      cost: 20.05,
      description: "Strep a dna dir probe",
    },
    {
      code: 87651,
      cost: 35.09,
      description: "Strep a dna amp probe",
    },
    {
      code: 87651,
      cost: 35.09,
      description: "Strep a dna amp probe",
    },
    {
      code: 87652,
      cost: 41.76,
      description: "Strep a dna quant",
    },
    {
      code: 87653,
      cost: 35.09,
      description: "Strep b dna amp probe",
    },
    {
      code: 87660,
      cost: 20.05,
      description: "Trichomonas vagin dir probe",
    },
    {
      code: 87661,
      cost: 35.09,
      description: "Trichomonas vaginalis amplif",
    },
    {
      code: 87662,
      cost: 51.31,
      description: "Zika virus dna/rna amp probe",
    },
    {
      code: 87797,
      cost: 30.03,
      description: "Detect agent nos dna dir",
    },
    {
      code: 87798,
      cost: 35.09,
      description: "Detect agent nos dna amp",
    },
    {
      code: 87799,
      cost: 42.84,
      description: "Detect agent nos dna quant",
    },
    {
      code: 87800,
      cost: 43.67,
      description: "Detect agnt mult dna direc",
    },
    {
      code: 87801,
      cost: 70.2,
      description: "Detect agnt mult dna ampli",
    },
    {
      code: 87801,
      cost: 70.2,
      description: "Detect agnt mult dna ampli",
    },
    {
      code: 87802,
      cost: 12.73,
      description: "Strep b assay w/optic",
    },
    {
      code: 87803,
      cost: 16,
      description: "Clostridium toxin a w/optic",
    },
    {
      code: 87804,
      cost: 16.55,
      description: "Influenza assay w/optic",
    },
    {
      code: 87804,
      cost: 16.55,
      description: "Influenza assay w/optic",
    },
    {
      code: 87806,
      cost: 32.77,
      description: "Hiv antigen w/hiv antibodies",
    },
    {
      code: 87806,
      cost: 32.77,
      description: "Hiv antigen w/hiv antibodies",
    },
    {
      code: 87807,
      cost: 13.1,
      description: "Rsv assay w/optic",
    },
    {
      code: 87807,
      cost: 13.1,
      description: "Rsv assay w/optic",
    },
    {
      code: 87808,
      cost: 15.29,
      description: "Trichomonas assay w/optic",
    },
    {
      code: 87808,
      cost: 15.29,
      description: "Trichomonas assay w/optic",
    },
    {
      code: 87809,
      cost: 21.76,
      description: "Adenovirus assay w/optic",
    },
    {
      code: 87809,
      cost: 21.76,
      description: "Adenovirus assay w/optic",
    },
    {
      code: 87810,
      cost: 35.29,
      description: "Chylmd trach assay w/optic",
    },
    {
      code: 87850,
      cost: 24.56,
      description: "N. gonorrhoeae assay w/optic",
    },
    {
      code: 87880,
      cost: 16.53,
      description: "Strep a assay w/optic",
    },
    {
      code: 87880,
      cost: 16.53,
      description: "Strep a assay w/optic",
    },
    {
      code: 87899,
      cost: 16.07,
      description: "Agent nos assay w/optic",
    },
    {
      code: 87899,
      cost: 16.07,
      description: "Agent nos assay w/optic",
    },
    {
      code: 87900,
      cost: 130.35,
      description: "Phenotype infect agent drug",
    },
    {
      code: 87901,
      cost: 257.45,
      description: "Genotype dna hiv reverse t",
    },
    {
      code: 87902,
      cost: 257.45,
      description: "Genotype dna/rna hep c",
    },
    {
      code: 87903,
      cost: 488.66,
      description: "Phenotype dna hiv w/culture",
    },
    {
      code: 87904,
      cost: 26.07,
      description: "Phenotype dna hiv w/clt add",
    },
    {
      code: 87905,
      cost: 12.22,
      description: "Sialidase enzyme assay",
    },
    {
      code: 87905,
      cost: 12.22,
      description: "Sialidase enzyme assay",
    },
    {
      code: 87906,
      cost: 128.73,
      description: "Genotype dna/rna hiv",
    },
    {
      code: 87910,
      cost: 257.45,
      description: "Genotype cytomegalovirus",
    },
    {
      code: 87912,
      cost: 257.45,
      description: "Genotype dna hepatitis b",
    },
    {
      code: 88130,
      cost: 17.98,
      description: "Sex chromatin identification",
    },
    {
      code: 88140,
      cost: 7.99,
      description: "Sex chromatin identification",
    },
    {
      code: 88142,
      cost: 20.26,
      description: "Cytopath c/v thin layer",
    },
    {
      code: 88143,
      cost: 23.04,
      description: "Cytopath c/v thin layer redo",
    },
    {
      code: 88147,
      cost: 50.56,
      description: "Cytopath c/v automated",
    },
    {
      code: 88148,
      cost: 16,
      description: "Cytopath c/v auto rescreen",
    },
    {
      code: 88150,
      cost: 15.12,
      description: "Cytopath c/v manual",
    },
    {
      code: 88152,
      cost: 27.64,
      description: "Cytopath c/v auto redo",
    },
    {
      code: 88153,
      cost: 24.03,
      description: "Cytopath c/v redo",
    },
    {
      code: 88155,
      cost: 14.65,
      description: "Cytopath c/v index add-on",
    },
    {
      code: 88164,
      cost: 15.12,
      description: "Cytopath tbs c/v manual",
    },
    {
      code: 88165,
      cost: 42.22,
      description: "Cytopath tbs c/v redo",
    },
    {
      code: 88166,
      cost: 15.12,
      description: "Cytopath tbs c/v auto redo",
    },
    {
      code: 88167,
      cost: 15.12,
      description: "Cytopath tbs c/v select",
    },
    {
      code: 88174,
      cost: 25.37,
      description: "Cytopath c/v auto in fluid",
    },
    {
      code: 88175,
      cost: 26.61,
      description: "Cytopath c/v auto fluid redo",
    },
    {
      code: 88230,
      cost: 116.49,
      description: "Tissue culture lymphocyte",
    },
    {
      code: 88233,
      cost: 140.73,
      description: "Tissue culture skin/biopsy",
    },
    {
      code: 88235,
      cost: 150.3,
      description: "Tissue culture placenta",
    },
    {
      code: 88237,
      cost: 143.75,
      description: "Tissue culture bone marrow",
    },
    {
      code: 88239,
      cost: 147.52,
      description: "Tissue culture tumor",
    },
    {
      code: 88240,
      cost: 13.07,
      description: "Cell cryopreserve/storage",
    },
    {
      code: 88241,
      cost: 12.09,
      description: "Frozen cell preparation",
    },
    {
      code: 88245,
      cost: 173.17,
      description: "Chromosome analysis 20-25",
    },
    {
      code: 88248,
      cost: 173.17,
      description: "Chromosome analysis 50-100",
    },
    {
      code: 88249,
      cost: 173.17,
      description: "Chromosome analysis 100",
    },
    {
      code: 88261,
      cost: 264.34,
      description: "Chromosome analysis 5",
    },
    {
      code: 88262,
      cost: 125.49,
      description: "Chromosome analysis 15-20",
    },
    {
      code: 88263,
      cost: 150.29,
      description: "Chromosome analysis 45",
    },
    {
      code: 88264,
      cost: 144.61,
      description: "Chromosome analysis 20-25",
    },
    {
      code: 88267,
      cost: 188.57,
      description: "Chromosome analys placenta",
    },
    {
      code: 88269,
      cost: 173.66,
      description: "Chromosome analys amniotic",
    },
    {
      code: 88271,
      cost: 21.42,
      description: "Cytogenetics dna probe",
    },
    {
      code: 88272,
      cost: 40.7,
      description: "Cytogenetics 3-5",
    },
    {
      code: 88273,
      cost: 34.81,
      description: "Cytogenetics 10-30",
    },
    {
      code: 88274,
      cost: 42.38,
      description: "Cytogenetics 25-99",
    },
    {
      code: 88275,
      cost: 51.19,
      description: "Cytogenetics 100-300",
    },
    {
      code: 88280,
      cost: 33.47,
      description: "Chromosome karyotype study",
    },
    {
      code: 88283,
      cost: 68.6,
      description: "Chromosome banding study",
    },
    {
      code: 88285,
      cost: 26.91,
      description: "Chromosome count additional",
    },
    {
      code: 88289,
      cost: 34.43,
      description: "Chromosome study additional",
    },
    {
      code: 88371,
      cost: 22.23,
      description: "Protein western blot tissue",
    },
    {
      code: 88372,
      cost: 26.22,
      description: "Protein analysis w/probe",
    },
    {
      code: 88720,
      cost: 5.02,
      description: "Bilirubin total transcut",
    },
    {
      code: 88738,
      cost: 5.02,
      description: "Hgb quant transcutaneous",
    },
    {
      code: 88740,
      cost: 9.37,
      description: "Transcutaneous carboxyhb",
    },
    {
      code: 88741,
      cost: 9.37,
      description: "Transcutaneous methb",
    },
    {
      code: 89050,
      cost: 4.72,
      description: "Body fluid cell count",
    },
    {
      code: 89051,
      cost: 5.6,
      description: "Body fluid cell count",
    },
    {
      code: 89055,
      cost: 4.27,
      description: "Leukocyte assessment fecal",
    },
    {
      code: 89060,
      cost: 7.33,
      description: "Exam synovial fluid crystals",
    },
    {
      code: 89125,
      cost: 5.88,
      description: "Specimen fat stain",
    },
    {
      code: 89160,
      cost: 4.85,
      description: "Exam feces for meat fibers",
    },
    {
      code: 89190,
      cost: 5.79,
      description: "Nasal smear for eosinophils",
    },
    {
      code: 89300,
      cost: 9.84,
      description: "Semen analysis w/huhner",
    },
    {
      code: 89300,
      cost: 9.84,
      description: "Semen analysis w/huhner",
    },
    {
      code: 89310,
      cost: 8.61,
      description: "Semen analysis w/count",
    },
    {
      code: 89320,
      cost: 12.31,
      description: "Semen anal vol/count/mot",
    },
    {
      code: 89321,
      cost: 12.05,
      description: "Semen anal sperm detection",
    },
    {
      code: 89321,
      cost: 12.05,
      description: "Semen anal sperm detection",
    },
    {
      code: 89322,
      cost: 15.5,
      description: "Semen anal strict criteria",
    },
    {
      code: 89325,
      cost: 10.67,
      description: "Sperm antibody test",
    },
    {
      code: 89329,
      cost: 19.59,
      description: "Sperm evaluation test",
    },
    {
      code: 89330,
      cost: 10.38,
      description: "Evaluation cervical mucus",
    },
    {
      code: 89331,
      cost: 19.59,
      description: "Retrograde ejaculation anal",
    },
    {
      code: "G0027",
      cost: 6.5,
      description: "Semen analysis",
    },
    {
      code: "G0103",
      cost: 19.31,
      description: "Psa screening",
    },
    {
      code: "G0123",
      cost: 20.26,
      description: "Screen cerv/vag thin layer",
    },
    {
      code: "G0143",
      cost: 27.05,
      description: "Scr c/v cyto,thinlayer,rescr",
    },
    {
      code: "G0144",
      cost: 43.97,
      description: "Scr c/v cyto,thinlayer,rescr",
    },
    {
      code: "G0145",
      cost: 26.49,
      description: "Scr c/v cyto,thinlayer,rescr",
    },
    {
      code: "G0147",
      cost: 15.12,
      description: "Scr c/v cyto, automated sys",
    },
    {
      code: "G0148",
      cost: 31.94,
      description: "Scr c/v cyto, autosys, rescr",
    },
    {
      code: "G0306",
      cost: 7.77,
      description: "Cbc/diffwbc w/o platelet",
    },
    {
      code: "G0307",
      cost: 6.47,
      description: "Cbc without platelet",
    },
    {
      code: "G0328",
      cost: 18.05,
      description: "Fecal blood scrn immunoassay",
    },
    {
      code: "G0328",
      cost: 18.05,
      description: "Fecal blood scrn immunoassay",
    },
    {
      code: "G0432",
      cost: 19.57,
      description: "Eia hiv-1/hiv-2 screen",
    },
    {
      code: "G0433",
      cost: 18.29,
      description: "Elisa hiv-1/hiv-2 screen",
    },
    {
      code: "G0433",
      cost: 18.29,
      description: "Elisa hiv-1/hiv-2 screen",
    },
    {
      code: "G0435",
      cost: 11.98,
      description: "Oral hiv-1/hiv-2 screen",
    },
    {
      code: "G0471",
      cost: 5,
      description: "Ven blood coll snf/hha",
    },
    {
      code: "G0472",
      cost: 46.35,
      description: "Hep c screen high risk/other",
    },
    {
      code: "G0472",
      cost: 46.35,
      description: "Hep c screen high risk/other",
    },
    {
      code: "G0475",
      cost: 24.08,
      description: "Hiv combination assay",
    },
    {
      code: "G0475",
      cost: 24.08,
      description: "Hiv combination assay",
    },
    {
      code: "G0476",
      cost: 35.09,
      description: "Hpv combo assay ca screen",
    },
    {
      code: "G0480",
      cost: 114.43,
      description: "Drug test def 1-7 classes",
    },
    {
      code: "G0481",
      cost: 156.59,
      description: "Drug test def 8-14 classes",
    },
    {
      code: "G0482",
      cost: 198.74,
      description: "Drug test def 15-21 classes",
    },
    {
      code: "G0483",
      cost: 246.92,
      description: "Drug test def 22+ classes",
    },
    {
      code: "G0499",
      cost: 28.27,
      description: "Hepb screen high risk indiv",
    },
    {
      code: "G0659",
      cost: 62.14,
      description: "Drug test def simple all cl",
    },
    {
      code: "G9143",
      cost: 120.72,
      description: "Warfarin respon genetic test",
    },
    {
      code: "P2028",
      cost: 4.95,
      description: "Cephalin floculation test",
    },
    {
      code: "P2029",
      cost: 4.95,
      description: "Congo red blood test",
    },
    {
      code: "P2031",
      cost: 4.95,
      description: "Hair analysis",
    },
    {
      code: "P2033",
      cost: 4.95,
      description: "Blood thymol turbidity",
    },
    {
      code: "P2038",
      cost: 4.95,
      description: "Blood mucoprotein",
    },
    {
      code: "P3000",
      cost: 15.12,
      description: "Screen pap by tech w md supv",
    },
    {
      code: "P9612",
      cost: 3,
      description: "Catheterize for urine spec",
    },
    {
      code: "P9615",
      cost: 3,
      description: "Urine specimen collect mult",
    },
    {
      code: "Q0111",
      cost: 15.12,
      description: "Wet mounts/ w preparations",
    },
    {
      code: "Q0112",
      cost: 5.83,
      description: "Potassium hydroxide preps",
    },
    {
      code: "Q0113",
      cost: 4.27,
      description: "Pinworm examinations",
    },
    {
      code: "Q0114",
      cost: 9.74,
      description: "Fern test",
    },
    {
      code: "Q0115",
      cost: 25,
      description: "Post-coital mucous exam",
    },
  ];
import { Formik } from "formik";

export function CodeEntryForm({
  formInputs,
  handleFormSubmit,
  validationSchema,
  handleValidation = () => {},
}) {
  const initializeValues = () =>
    formInputs.reduce((prev, current) => {
      return { ...prev, [current.id]: current.initialValue };
    }, {});
  const renderInputs = (
    id,
    type,
    label,
    error,
    touched,
    handleChange,
    handleBlur,
    value
  ) => {
    const renderInputType = (inputType) => {
      switch (inputType) {
        case "currency":
          return (
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id={id}
              type={"number"}
              step="0.01"
              name={id}
              onChange={handleChange}
              onBlur={handleBlur}
              value={value}
            />
          );
        default:
          return (
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id={id}
              type={type}
              name={id}
              onChange={handleChange}
              onBlur={handleBlur}
              value={value}
            />
          );
      }
    };
    return (
      <div className="mb-4" key={id}>
        <label
          className="block text-gray-700 text-sm font-bold mb-2"
          htmlFor={id}
        >
          {label}
        </label>
        {renderInputType(type)}
        {error && touched && error}
      </div>
    );
  };
  return (
    <Formik
      initialValues={initializeValues()}
      validationSchema={validationSchema}
      validate={(_values) => {
        const errors = {};
        handleValidation(_values);

        // if (!values.email) {
        //   errors.email = "Required";
        // } else if (
        //   !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        // ) {
        //   errors.email = "Invalid email address";
        // }

        return errors;
      }}
      onSubmit={async (values, { setSubmitting, setFieldError, resetForm }) => {
        const errors = {};
        try {
          await handleFormSubmit(values);
          resetForm({ values: initializeValues() });
          setSubmitting(false);
        } catch (e) {
          console.log(e);
          setFieldError("code", "code not found");
          setSubmitting(false);
          return errors;
        }
      }}
    >
      {({
        values,

        errors,

        touched,

        handleChange,

        handleBlur,

        handleSubmit,

        isSubmitting,

        /* and other goodies */
      }) => (
        <form
          onSubmit={handleSubmit}
          className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 container mx-auto flex items-center justify-around content-center gap-x-1"
        >
          {formInputs.map((input) => {
            if (input.isInForm) {
              return renderInputs(
                input.id,
                input.type,
                input.label,
                errors[input.id],
                touched[input.id],
                handleChange,
                handleBlur,
                values[input.id]
              );
            } else {
              return null;
            }
          })}
          <button type="submit" disabled={isSubmitting}>
            Submit
          </button>
        </form>
      )}
    </Formik>
  );
}

import { useState } from "react";

import { CodeEntryForm } from "../components/CodeEntryForm";
import { getLab } from "../api/labsApi";
import { Result } from "../components/Result";
import { format } from "date-fns";
import * as yup from "yup";

const labAttributes = [
  {
    id: "code",
    label: "code",
    type: "text",
    isRequired: true,
    initialValue: "",
    isInForm: true,
    size: "sm",
  },
  {
    id: "claimDate",
    label: "claim date",
    type: "date",
    isRequired: true,
    initialValue: format(new Date(), "yyyy-MM-dd"),
    isInForm: true,
    size: "sm",
  },
  {
    id: "claimAmount",
    label: "claim amount",
    type: "currency",
    isRequired: true,
    initialValue: 0,
    isInForm: true,
    size: "sm",
  },
  {
    id: "cost",
    label: "actual amount",
    type: "currency",
    isRequired: false,
    initialValue: 0,
    isInForm: false,
    size: "sm",
  },
  {
    id: "description",
    label: "description",
    type: "text",
    isRequired: false,
    initialValue: "",
    isInForm: false,
    size: "lg",
  },
];

const formSchema = yup.object().shape({
  code: yup.string().required(),
  claimDate: yup
    .string()
    .required()
    .default(function () {
      return format(new Date(), "yyyy-MM-dd");
    }),
  claimAmount: yup.number().required(),
});
export function LabsPage() {
  const [results, setResults] = useState([]);
  const [totalBilled, setTotalBilled] = useState({
    claimAmount: 0,
    actualAmount: 0,
  });
  function getTotals(updatedResults) {
    return updatedResults.reduce(
      (prev, current) => {
        const foundclaimAmount = current.labelAndValues.find(
          (c) => c.id === "claimAmount"
        );
        return {
          claimAmount: prev.claimAmount + foundclaimAmount.value,
          actualAmount:
            prev.actualAmount +
            current.labelAndValues.find((c) => c.id === "cost").value,
        };
      },
      {
        claimAmount: 0,
        actualAmount: 0,
      }
    );
  }
  async function handleSubmit(values) {
    const result = await getLab(
      values.code,
      values.claimDate,
      values.claimAmount,
      labAttributes
    );

    const updatedResults = [...results, result];
    const total = getTotals(updatedResults);
    setResults(updatedResults);
    setTotalBilled(total);
  }
  const handleRemove = (key) => {
    const updatedResults = results.filter((value) => {
      return value.key !== key;
    });
    const total = getTotals(updatedResults);
    setResults(updatedResults);
    setTotalBilled(total);
  };
  return (
    <div className="container mx-auto">
      <CodeEntryForm
        validationSchema={formSchema}
        formInputs={labAttributes}
        handleFormSubmit={handleSubmit}
      />
      <div>
        claim amount total:{" "}
        {totalBilled.claimAmount.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        })}{" "}
        actual amount total:{" "}
        {totalBilled.actualAmount.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        })}
      </div>
      {results.map((r) => {
        return (
          <Result key={r.key} {...r} index={r.key} onRemove={handleRemove} />
        );
      })}
    </div>
  );
}

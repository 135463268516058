import './App.css';
import {Home} from './pages/Home'
import {NavBar} from './components/NavBar';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { LabsPage } from './pages/Labs';

const navigation = [
  { name: 'Home', href:"/home", current: true, component: () => <Home />},
  { name: "labs", href: "/labs", current: false, component: () => <LabsPage />},
];

function App() {
  return (
    <BrowserRouter>
      <NavBar navigation={navigation}/>
      <Switch>
        {navigation.map(n => {
          return  <Route  key={n.href} path={n.href}>
         {n.component()}
        </Route>
        })}
        </Switch>
    </BrowserRouter>
  );
}

export default App;
